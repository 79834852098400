/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { render as mustacheRender } from "mustache";
import { rtNotificationDismissed, rtNotificationClear } from "../../../../../store/actions/rt_notifications";
import { Link } from "react-router-dom";

const DEBUG = false;

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

const UserNotificationsDropdownF = props => {
    const [key, setKey] = useState("Alerts");
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    const [show, setShow] = useState(false);

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
        };
    }, [uiService]);

    const notifications = props.rt_notifications.notifications;

    const dismissNotification = id => {
        props.dismissNotification(id);
        if (notifications.length === 1) {
            setShow(false);
        }
    };

    const clearNotifications = () => {
        props.clearNotifications();
        setShow(false);
    };

    const renderResource = n => {
        if (!(n.resource_type && n.resource_details)) {
            console.error(`missing resource type/details in notification`, n);
            return "";
        }

        let rd = n.resource_details;

        if (n.resource_type === "slot") {
            return (
                <div>
                    <span>{props.t("Slot")} </span>
                    <Link to={`/tenants/${rd.tenant_id}`}>{rd.tenant_name}</Link>
                    <span> / </span>
                    <Link to={`/locations/${rd.location_id}`}>{rd.location_name}</Link>
                    <span> / </span>
                    <Link to={`/slots/${rd.slot_id}`}>{rd.slot_name}</Link>
                </div>
            );
        } else if (n.resource_type === "location") {
            return (
                <div>
                    <span>{props.t("Location")} </span>
                    <Link to={`/tenants/${rd.tenant_id}`}>{rd.tenant_name}</Link>
                    <span> / </span>
                    <Link to={`/locations/${rd.location_id}`}>{rd.location_name}</Link>
                </div>
            );
        } else if (n.resource_type === "tenant") {
            return (
                <div>
                    <span>{props.t("Tenant")} </span>
                    <Link to={`/tenants/${rd.tenant_id}`}>{rd.tenant_name}</Link>
                </div>
            );
        } else if (n.resource_type === "user") {
            return (
                <div>
                    <span>{props.t("User")} </span>
                    <Link to={`/acl-users/${rd.user_id}`}>{rd.user_name}</Link>
                </div>
            );
        }

        return <div>Unknown resource type</div>;
    };

    const toggle = isOpen => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-sm px-2 pulse pulse-white"
                        id="kt_quick_notifications_toggle"
                    >
                        <span className="svg-icon svg-icon-xl">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
                        </span>
                        <span className="pulse-ring"></span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <Dropdown show={show} onToggle={toggle} drop="down" alignRight>
                    <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="user-notification-tooltip">Notifications</Tooltip>}
                        >
                            <div
                                className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-sm px-2 pulse pulse-white"
                                id="kt_quick_notifications_toggle"
                            >
                                <span className="svg-icon svg-icon-xl">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
                                </span>
                                {notifications.length > 0 && (
                                    <>
                                        <span className="pulse-ring" />
                                        <span className="badge badge-danger">{notifications.length}</span>
                                    </>
                                )}
                            </div>
                        </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                        {/** Head */}
                        <div
                            className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                            style={{ backgroundImage1: `url(${bgImage})` }}
                        >
                            <h4 className="d-flex flex-center rounded-top">
                                <span className="text-black">Notifications</span>
                                {notifications.length > 0 && (
                                    <>
                                        <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                                            {notifications.length} new
                                        </span>
                                        <button
                                            type="button"
                                            className="btn btn-text btn-primary btn-sm font-weight-bold btn-font-md ml-2"
                                            onClick={clearNotifications}
                                        >
                                            Clear
                                        </button>
                                    </>
                                )}
                            </h4>

                            {props.rt_notifications.notifications.length ? (
                                <div className="p-8">
                                    <PerfectScrollbar
                                        options={perfectScrollbarOptions}
                                        className="scroll pr-7 mr-n7"
                                        style={{ maxHeight: "300px", position: "relative" }}
                                    >
                                        {props.rt_notifications.notifications.map(n => {
                                            let tmpl = null;

                                            let lang = props.i18n.language;

                                            DEBUG && console.log("lang", lang);

                                            if (lang in n.content) {
                                                tmpl = n.content[lang];
                                            } else if ("en" in n.content) {
                                                tmpl = n.content["en"];
                                            } else {
                                                tmpl = "missing template content";
                                            }

                                            return (
                                                <div
                                                    key={`notification_${n.id}`}
                                                    className="d-flex align-items-center justify-content-between mb-6"
                                                >
                                                    <div className="navi-text">
                                                        {renderResource(n)}
                                                        <div className="font-weight-bold">
                                                            {mustacheRender(tmpl, n.data)}
                                                        </div>
                                                        <div className="text-muted">
                                                            {moment(n.timestamp).fromNow()}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-sm"
                                                            onClick={() => dismissNotification(n.id)}
                                                        >
                                                            x
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </PerfectScrollbar>
                                </div>
                            ) : (
                                <div className="d-flex flex-center text-center text-muted min-h-200px">
                                    All caught up!
                                    <br />
                                    No new notifications.
                                </div>
                            )}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
};

const mapState = state => {
    const { rt_notifications } = state;
    return { rt_notifications };
};

const actionCreators = {
    dismissNotification: rtNotificationDismissed,
    clearNotifications: rtNotificationClear,
};

export const UserNotificationsDropdown = withTranslation()(
    connect(mapState, actionCreators)(UserNotificationsDropdownF)
);
