import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Content } from "./Content";
import { hasSubMenuAccess } from "../header/MenuAccess";
import { routes } from "../../../../router";
import { getCurrentUrl } from "../../../_helpers";

export function ContentInnerPageRoute({ children, component, render, ...props }) {
  let cardBodyPaddingClass = '';
  let cardDashboardClass = 'card';
  let history = useHistory();

  if (props.path.startsWith('/dashboard')) {
    cardBodyPaddingClass = 'px-3 pb-3 pt-1';
    cardDashboardClass = 'card-dashboard';
  }
  
  if (props.path === getCurrentUrl(props.location) && !hasSubMenuAccess(props.path)) {
    let index = 0;
    while (index < routes.length ) {
      if(hasSubMenuAccess(routes[index].path)){
        history.push(routes[index].path);
        break;
      }
      index++;
    }
  }

  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          return <Content>
            <div className={cardDashboardClass}>
              <div className={`card-body ${cardBodyPaddingClass}`}>
                {children(routeProps)}
              </div>
            </div>
          </Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content><div className={cardDashboardClass}>
            <div className={`card-body ${cardBodyPaddingClass}`}>{children}
            </div>
          </div>
          </Content>;
        }

        if (component) {
          return (
            <Content>
              <div className={cardDashboardClass}>
                <div className={`card-body ${cardBodyPaddingClass}`}>
                  {React.createElement(component, routeProps)}
                </div>
              </div></Content>
          );
        }

        if (render) {
          return <Content>
            <div className={cardDashboardClass}>
              <div className={`card-body ${cardBodyPaddingClass}`}>
                {render(routeProps)}
              </div>
            </div>
          </Content>;
        }

        return null;
      }}
    </Route>
  );
}

