import { dashboardConstants } from './dashboardConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  id: "",
  widgets: [],
  error: ''
});

export function dashboard_settings(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case dashboardConstants.DASHBOARD_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
      };
    case dashboardConstants.DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        widgets: action.dashboardSettings.widgets ? action.dashboardSettings.widgets : [],
      };
    case dashboardConstants.DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case dashboardConstants.DASHBOARD_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };

    case dashboardConstants.DASHBOARD_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case dashboardConstants.DASHBOARD_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
        }
      }
      return {
        ...state,
        requestSaveActive: false,
      }
    case dashboardConstants.DASHBOARD_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
