export const monthlyReportsConstants = {
  MONTHLY_REPORT_LIST_REQUEST: 'MONTHLY_REPORT_LIST_REQUEST',
  MONTHLY_REPORT_LIST_SUCCESS: 'MONTHLY_REPORT_LIST_SUCCESS',
  MONTHLY_REPORT_LIST_FAILURE: 'MONTHLY_REPORT_LIST_FAILURE',
  MONTHLY_REPORT_LIST_CHANGED: 'MONTHLY_REPORT_LIST_CHANGED',
  MONTHLY_REPORT_LIST_PAGINATION: 'MONTHLY_REPORT_LIST_PAGINATION',

  MONTHLY_REPORT_LOAD_REQUEST: 'MONTHLY_REPORT_LOAD_REQUEST',
  MONTHLY_REPORT_LOAD_SUCCESS: 'MONTHLY_REPORT_LOAD_SUCCESS',
  MONTHLY_REPORT_LOAD_FAILURE: 'MONTHLY_REPORT_LOAD_FAILURE',

  MONTHLY_REPORT_SAVE_REQUEST: 'MONTHLY_REPORT_SAVE_REQUEST',
  MONTHLY_REPORT_SAVE_SUCCESS: 'MONTHLY_REPORT_SAVE_SUCCESS',
  MONTHLY_REPORT_SAVE_FAILURE: 'MONTHLY_REPORT_SAVE_FAILURE',
};
