export const playerScansConstants = {
  PLAYER_SCANS_LIST_REQUEST: 'PLAYER_SCANS_LIST_REQUEST',
  PLAYER_SCANS_LIST_SUCCESS: 'PLAYER_SCANS_LIST_SUCCESS',
  PLAYER_SCANS_LIST_FAILURE: 'PLAYER_SCANS_LIST_FAILURE',
  PLAYER_SCANS_LIST_CHANGED: 'PLAYER_SCANS_LIST_CHANGED',
  PLAYER_SCANS_LIST_PAGINATION: 'PLAYER_SCANS_LIST_PAGINATION',

  PLAYER_SCANS_LOAD_REQUEST: 'PLAYER_SCANS_LOAD_REQUEST',
  PLAYER_SCANS_LOAD_SUCCESS: 'PLAYER_SCANS_LOAD_SUCCESS',
  PLAYER_SCANS_LOAD_FAILURE: 'PLAYER_SCANS_LOAD_FAILURE',

  PLAYER_SCANS_SAVE_REQUEST: 'PLAYER_SCANS_SAVE_REQUEST',
  PLAYER_SCANS_SAVE_SUCCESS: 'PLAYER_SCANS_SAVE_SUCCESS',
  PLAYER_SCANS_SAVE_FAILURE: 'PLAYER_SCANS_SAVE_FAILURE',
};
