export const jackpotConstants = {
  JACKPOT_LIST_REQUEST: 'JACKPOT_LIST_REQUEST',
  JACKPOT_LIST_SUCCESS: 'JACKPOT_LIST_SUCCESS',
  JACKPOT_LIST_FAILURE: 'JACKPOT_LIST_FAILURE',
  JACKPOT_LIST_CHANGED: 'JACKPOT_LIST_CHANGED',
  JACKPOT_LIST_PAGINATION: 'JACKPOT_LIST_PAGINATION',

  JACKPOT_LOAD_REQUEST: 'JACKPOT_LOAD_REQUEST',
  JACKPOT_LOAD_SUCCESS: 'JACKPOT_LOAD_SUCCESS',
  JACKPOT_LOAD_FAILURE: 'JACKPOT_LOAD_FAILURE',

  JACKPOT_SAVE_REQUEST: 'JACKPOT_SAVE_REQUEST',
  JACKPOT_SAVE_SUCCESS: 'JACKPOT_SAVE_SUCCESS',
  JACKPOT_SAVE_FAILURE: 'JACKPOT_SAVE_FAILURE',
  JACKPOT_DONE_SUCCESS: 'JACKPOT_DONE_SUCCESS'
};
