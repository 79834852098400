export const rawaggregationConstants = {
  RAWAGGREGATION_LIST_REQUEST: 'RAWAGGREGATION_LIST_REQUEST',
  RAWAGGREGATION_LIST_SUCCESS: 'RAWAGGREGATION_LIST_SUCCESS',
  RAWAGGREGATION_LIST_FAILURE: 'RAWAGGREGATION_LIST_FAILURE',
  RAWAGGREGATION_LIST_CHANGED: 'RAWAGGREGATION_LIST_CHANGED',
  RAWAGGREGATION_LIST_PAGINATION: 'RAWAGGREGATION_LIST_PAGINATION',

  RAWAGGREGATION_SAVE_REQUEST: 'RAWAGGREGATION_SAVE_REQUEST',
  RAWAGGREGATION_SAVE_SUCCESS: 'RAWAGGREGATION_SAVE_SUCCESS',
  RAWAGGREGATION_SAVE_FAILURE: 'RAWAGGREGATION_SAVE_FAILURE',
};
