export const gamesConstants = {
  GAMES_LIST_REQUEST: 'GAMES_LIST_REQUEST',
  GAMES_LIST_SUCCESS: 'GAMES_LIST_SUCCESS',
  GAMES_LIST_FAILURE: 'GAMES_LIST_FAILURE',
  GAMES_LIST_CHANGED: 'GAMES_LIST_CHANGED',
  GAMES_LIST_PAGINATION: 'GAMES_LIST_PAGINATION',

  GAMES_LOAD_REQUEST: 'GAMES_LOAD_REQUEST',
  GAMES_LOAD_SUCCESS: 'GAMES_LOAD_SUCCESS',
  GAMES_LOAD_FAILURE: 'GAMES_LOAD_FAILURE',

  GAMES_SAVE_REQUEST: 'GAMES_SAVE_REQUEST',
  GAMES_SAVE_SUCCESS: 'GAMES_SAVE_SUCCESS',
  GAMES_SAVE_FAILURE: 'GAMES_SAVE_FAILURE',
};
