import { appConstants } from "../constants/app";

export const appShowVersionCheckModal = () => ({
    type: appConstants.SHOW_VERSION_CHECK_MODAL
});

export const appHideVersionCheckModal = () => ({
    type: appConstants.HIDE_VERSION_CHECK_MODAL
});

export const appBuildInfoReceived = buildInfo => ({
    type: appConstants.BUILD_INFO_RECEIVED,
    buildInfo
});
