import { paymentTransactionConstants } from './paymentTransactionConstants';
import {userConstants} from "../../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  logItems: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "timestamp",
  sortDirection: "desc",
  startDate: null,
  endDate: null,
  total: 0,
  logTotal: 0,
  error: '',
  query: "",
  inProgress: true,
});

export function reports_payment_transactions(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LIST_REQUEST:
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LOAD_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        logTotal: 0,
        inProgress: true,
      };
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LIST_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.paymentTransactions.rows ? action.paymentTransactions.rows : [],
        total: action.paymentTransactions.total ? action.paymentTransactions.total : 0,
        inProgress: false,
      };
    }
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LOAD_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        logItems: action.paymentTransaction.rows ? action.paymentTransaction.rows : [],
        logTotal: action.paymentTransaction.total ? action.paymentTransaction.total : 0,
        inProgress: false,
      };
    }
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case paymentTransactionConstants.PAYMENT_TRANSACTIONS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.startDate !== action.pagination.startDate
        || state.endDate !== action.pagination.endDate
        || state.exactValues !== action.pagination.exactValues
        || state.exactFields !== action.pagination.exactFields
        || state.paymentTransactionId !== action.pagination.paymentTransactionId
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        exactValues: action.pagination.exactValues,
        exactFields: action.pagination.exactFields,
        paymentTransactionId: action.pagination.paymentTransactionId,
        shouldReload
      };
    }
    default:
      return state
  }
}
