import { counterConstants } from './countersConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  total: 0,
  error: '',
  smibid: '',
  dateStart: null,
  dateEnd: null,
  timeRange: null,
  gameNr: '',
  meterId: '',
  meta: [],
  inProgress: true,
});

export function counters(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case counterConstants.COUNTER_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case counterConstants.COUNTER_LIST_SUCCESS: {
      const counters = [];
      let key = 1;
      action.counters.counters && action.counters.counters.forEach((e) => {
        const el = { ...e };
        el._key = key++;
        counters.push(el);
      })

      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: counters,
        total: action.counters.total_counters ? action.counters.total_counters : 0,
        meta: action.counters.meta && action.counters.meta.counters ? action.counters.meta.counters : [],
        inProgress: false,
      };
    }
    case counterConstants.COUNTER_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case counterConstants.COUNTER_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case counterConstants.COUNTER_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.dateStart !== action.pagination.dateStart
        || state.dateEnd !== action.pagination.dateEnd
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        smibid: action.pagination.smibid,
        dateStart: action.pagination.dateStart,
        dateEnd: action.pagination.dateEnd,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        gameNr: action.pagination.gameNr,
        meterId: action.pagination.meterId,
        shouldReload
      };
    }

    case counterConstants.COUNTER_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case counterConstants.COUNTER_SAVE_SUCCESS:
      return {
        ...state,
        requestSaveActive: false,
      }
    case counterConstants.COUNTER_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
