import { entityGroupConstants } from './entityGroupConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  total: 0,
  error: '',
  query: "",
  entityType: "location",
  inProgress: true,
});

export function entity_groups(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case entityGroupConstants.ENTITY_GROUP_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case entityGroupConstants.ENTITY_GROUP_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.entity_groups.rows ? action.entity_groups.rows : [],
        total: action.entity_groups.total ? action.entity_groups.total : 0,
        inProgress: false,
      };
    case entityGroupConstants.ENTITY_GROUP_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case entityGroupConstants.ENTITY_GROUP_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case entityGroupConstants.ENTITY_GROUP_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.entityType !== action.pagination.entityType
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        entityType: action.pagination.entityType,
        shouldReload
      };
    }

    case entityGroupConstants.ENTITY_GROUP_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case entityGroupConstants.ENTITY_GROUP_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.entity_group],
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(t => t.id === action.entity_group.id ? action.entity_group : t)
      }
    case entityGroupConstants.ENTITY_GROUP_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
