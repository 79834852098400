export const collectorCheckpointConstants = {
  COLLECTOR_CHECKPOINTS_LIST_REQUEST: 'COLLECTOR_CHECKPOINTS_LIST_REQUEST',
  COLLECTOR_CHECKPOINTS_LIST_SUCCESS: 'COLLECTOR_CHECKPOINTS_LIST_SUCCESS',
  COLLECTOR_CHECKPOINTS_LIST_FAILURE: 'COLLECTOR_CHECKPOINTS_LIST_FAILURE',
  COLLECTOR_CHECKPOINTS_LIST_CHANGED: 'COLLECTOR_CHECKPOINTS_LIST_CHANGED',
  COLLECTOR_CHECKPOINTS_LIST_PAGINATION: 'COLLECTOR_CHECKPOINTS_LIST_PAGINATION',

  COLLECTOR_CHECKPOINTS_LOAD_REQUEST: 'COLLECTOR_CHECKPOINTS_LOAD_REQUEST',
  COLLECTOR_CHECKPOINTS_LOAD_SUCCESS: 'COLLECTOR_CHECKPOINTS_LOAD_SUCCESS',
  COLLECTOR_CHECKPOINTS_LOAD_FAILURE: 'COLLECTOR_CHECKPOINTS_LOAD_FAILURE',

  COLLECTOR_CHECKPOINTS_SAVE_REQUEST: 'COLLECTOR_CHECKPOINTS_SAVE_REQUEST',
  COLLECTOR_CHECKPOINTS_SAVE_SUCCESS: 'COLLECTOR_CHECKPOINTS_SAVE_SUCCESS',
  COLLECTOR_CHECKPOINTS_SAVE_FAILURE: 'COLLECTOR_CHECKPOINTS_SAVE_FAILURE',
};
