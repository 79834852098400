export const tenantConstants = {
  TENANT_LIST_REQUEST: 'TENANT_LIST_REQUEST',
  TENANT_LIST_SUCCESS: 'TENANT_LIST_SUCCESS',
  TENANT_LIST_FAILURE: 'TENANT_LIST_FAILURE',
  TENANT_LIST_CHANGED: 'TENANT_LIST_CHANGED',
  TENANT_LIST_PAGINATION: 'TENANT_LIST_PAGINATION',

  TENANT_LOAD_REQUEST: 'TENANT_LOAD_REQUEST',
  TENANT_LOAD_SUCCESS: 'TENANT_LOAD_SUCCESS',
  TENANT_LOAD_FAILURE: 'TENANT_LOAD_FAILURE',

  TENANT_SAVE_REQUEST: 'TENANT_SAVE_REQUEST',
  TENANT_SAVE_SUCCESS: 'TENANT_SAVE_SUCCESS',
  TENANT_SAVE_FAILURE: 'TENANT_SAVE_FAILURE',
};
