export const entityGroupConstants = {
  ENTITY_GROUP_LIST_REQUEST: 'ENTITY_GROUP_LIST_REQUEST',
  ENTITY_GROUP_LIST_SUCCESS: 'ENTITY_GROUP_LIST_SUCCESS',
  ENTITY_GROUP_LIST_FAILURE: 'ENTITY_GROUP_LIST_FAILURE',
  ENTITY_GROUP_LIST_CHANGED: 'ENTITY_GROUP_LIST_CHANGED',
  ENTITY_GROUP_LIST_PAGINATION: 'ENTITY_GROUP_LIST_PAGINATION',

  ENTITY_GROUP_LOAD_REQUEST: 'ENTITY_GROUP_LOAD_REQUEST',
  ENTITY_GROUP_LOAD_SUCCESS: 'ENTITY_GROUP_LOAD_SUCCESS',
  ENTITY_GROUP_LOAD_FAILURE: 'ENTITY_GROUP_LOAD_FAILURE',

  ENTITY_GROUP_SAVE_REQUEST: 'ENTITY_GROUP_SAVE_REQUEST',
  ENTITY_GROUP_SAVE_SUCCESS: 'ENTITY_GROUP_SAVE_SUCCESS',
  ENTITY_GROUP_SAVE_FAILURE: 'ENTITY_GROUP_SAVE_FAILURE',
};
