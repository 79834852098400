export const slotGroupsConstants = {
  SLOT_GROUPS_LIST_REQUEST: 'SLOT_GROUPS_LIST_REQUEST',
  SLOT_GROUPS_LIST_SUCCESS: 'SLOT_GROUPS_LIST_SUCCESS',
  SLOT_GROUPS_LIST_FAILURE: 'SLOT_GROUPS_LIST_FAILURE',
  SLOT_GROUPS_LIST_CHANGED: 'SLOT_GROUPS_LIST_CHANGED',
  SLOT_GROUPS_LIST_PAGINATION: 'SLOT_GROUPS_LIST_PAGINATION',

  SLOT_GROUPS_LOAD_REQUEST: 'SLOT_GROUPS_LOAD_REQUEST',
  SLOT_GROUPS_LOAD_SUCCESS: 'SLOT_GROUPS_LOAD_SUCCESS',
  SLOT_GROUPS_LOAD_FAILURE: 'SLOT_GROUPS_LOAD_FAILURE',

  SLOT_GROUPS_SAVE_REQUEST: 'SLOT_GROUPS_SAVE_REQUEST',
  SLOT_GROUPS_SAVE_SUCCESS: 'SLOT_GROUPS_SAVE_SUCCESS',
  SLOT_GROUPS_SAVE_FAILURE: 'SLOT_GROUPS_SAVE_FAILURE',
};
