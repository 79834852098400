export const gameProfilesConstants = {
  GAME_PROFILES_LIST_REQUEST: 'GAME_PROFILES_LIST_REQUEST',
  GAME_PROFILES_LIST_SUCCESS: 'GAME_PROFILES_LIST_SUCCESS',
  GAME_PROFILES_LIST_FAILURE: 'GAME_PROFILES_LIST_FAILURE',
  GAME_PROFILES_LIST_CHANGED: 'GAME_PROFILES_LIST_CHANGED',
  GAME_PROFILES_LIST_PAGINATION: 'GAME_PROFILES_LIST_PAGINATION',

  GAME_PROFILES_LOAD_REQUEST: 'GAME_PROFILES_LOAD_REQUEST',
  GAME_PROFILES_LOAD_SUCCESS: 'GAME_PROFILES_LOAD_SUCCESS',
  GAME_PROFILES_LOAD_FAILURE: 'GAME_PROFILES_LOAD_FAILURE',

  GAME_PROFILES_SAVE_REQUEST: 'GAME_PROFILES_SAVE_REQUEST',
  GAME_PROFILES_SAVE_SUCCESS: 'GAME_PROFILES_SAVE_SUCCESS',
  GAME_PROFILES_SAVE_FAILURE: 'GAME_PROFILES_SAVE_FAILURE',
};
