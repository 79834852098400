import { logConstants } from './logConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "smib_id",
  sortDirection: "desc",
  total: 0,
  error: '',
  smibid: '',
  dateStart: null,
  dateEnd: null,
  timeRange: null,
  inProgress: true,
});

export function logs(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case logConstants.LOG_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case logConstants.LOG_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.logs.rows ? action.logs.rows : [],
        total: action.logs.total ? action.logs.total : 0,
        inProgress: false,
      };
    case logConstants.LOG_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case logConstants.LOG_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case logConstants.LOG_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || (action.pagination.dateStart && action.pagination.dateEnd)
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        smibid: action.pagination.smibid,
        dateStart: action.pagination.dateStart,
        dateEnd: action.pagination.dateEnd,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        shouldReload
      };
    }

    default:
      return state
  }
}
