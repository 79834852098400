import { tenantGroupsConstants } from './tenantGroupsConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  error: '',
  query: "",
  inProgress: true,
});

export function tenantsGroups(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case tenantGroupsConstants.TENANT_GROUPS_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case tenantGroupsConstants.TENANT_GROUPS_LIST_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.tenantsGroups.rows ? action.tenantsGroups.rows : [],
        total: action.tenantsGroups.total ? action.tenantsGroups.total : 0,
        inProgress: false,
      };
    }
    case tenantGroupsConstants.TENANT_GROUPS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case tenantGroupsConstants.TENANT_GROUPS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case tenantGroupsConstants.TENANT_GROUPS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.exactFields !== action.pagination.exactFields
        || state.exactValues !== action.pagination.exactValues
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        exactFields: action.pagination.exactFields,
        exactValues: action.pagination.exactValues,
        shouldReload
      };
    }

    case tenantGroupsConstants.TENANT_GROUPS_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case tenantGroupsConstants.TENANT_GROUPS_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.tenant]
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(l => l.id === action.tenant.id ? action.tenant : l)
      }
    case tenantGroupsConstants.TENANT_GROUPS_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
