import { slotdataConstants } from './slotDataConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "receivedDate",
  sortDirection: "desc",
  total: 0,
  error: '',
  startDate: null,
  endDate: null,
  timeRange: null,
  inProgress: true,
});

export function slotdata(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case slotdataConstants.SLOTDATA_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case slotdataConstants.SLOTDATA_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.events.rows ? action.events.rows : [],
        total: action.events.total ? action.events.total : 0,
        inProgress: false,
      };
    case slotdataConstants.SLOTDATA_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case slotdataConstants.SLOTDATA_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case slotdataConstants.SLOTDATA_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.startDate !== action.pagination.startDate
        || state.endDate !== action.pagination.endDate
        || state.shouldReload
        || (action.pagination.startDate && action.pagination.endDate)) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        shouldReload
      };
    }

    case slotdataConstants.SLOTDATA_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case slotdataConstants.SLOTDATA_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.slotdata]
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(l => l.interfaceID === action.slotdata.interfaceID ? action.slotdata : l)
      }
    case slotdataConstants.SLOTDATA_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
