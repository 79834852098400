import { messagesConstants } from './messagesConstants';
import {userConstants} from "../../auth/_redux/authConstants";
import moment from "moment";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  total: 0,
  error: '',
  smibid: '',
  dateStart: null,
  dateEnd: null,
  timeRange: null,
  query: '',
  meta: [],
  inProgress: true,
});

export function messages(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case messagesConstants.MESSAGES_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case messagesConstants.MESSAGES_LIST_SUCCESS: {
      let events = [];
      const eventsByKeys = {};
      let key = 1;
      action.events.events && action.events.events.forEach((e) => {
        const el = { ...e };
        el._key = key++;

        //const flagIndex = Math.floor(Math.random() * Math.floor(4));
        //const flags = [0, 1, 2, 4];
        //el.flags = flags[flagIndex];

        events.push(el);
        eventsByKeys[el._key] = el;
      });

      events.sort((a, b) => {
        // server time is now returned as ISO string. Must parse before compare
        const ast = moment(a.server_time_nano).valueOf();
        const bst = moment(b.server_time_nano).valueOf();

        if (ast !== bst)
          return ast - bst;

          if ("message_seq_nr" in a && "message_seq_nr" in b) {
            if (a.message_seq_nr !== b.message_seq_nr)
              return a.message_seq_nr - b.message_seq_nr;
          }
  
          if ("event_seq_nr" in a && "event_seq_nr" in b) {
            if (a.event_seq_nr !== b.event_seq_nr)
              return a.event_seq_nr - b.event_seq_nr;
          }

          return 0;
        });

      // TODO: Serban check this
      events.forEach((el, index) => {
        el.has_seq_error = false;
        el.reset = false;
        if (index) {
          if (el.message_seq_nr - events[index - 1].message_seq_nr > 1 && events[index - 1].event_seq_nr) {
            el.has_seq_error = true;
          } else if (el.message_seq_nr - events[index - 1].message_seq_nr < 0) {
            el.reset = true;
          }
        }
        eventsByKeys[el._key] = el;
      });

      events = [];
      const keys = Object.keys(eventsByKeys).sort((a, b) => { return a - b });
      keys.forEach(k => {
        events.push(eventsByKeys[k]);
      });

      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: events,
        total: action.events.total_events ? action.events.total_events : 0,
        meta: action.events.meta && action.events.meta.events ? action.events.meta.events : [],
        inProgress: false,
      };
    }
    case messagesConstants.MESSAGES_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case messagesConstants.MESSAGES_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case messagesConstants.MESSAGES_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.dateStart !== action.pagination.dateStart
        || state.dateEnd !== action.pagination.dateEnd
        || state.query !== action.pagination.query
        || state.show_all !== action.pagination.show_all
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        smibid: action.pagination.smibid,
        dateStart: action.pagination.dateStart,
        dateEnd: action.pagination.dateEnd,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        show_all: action.pagination.show_all,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        shouldReload
      };
    }

    case messagesConstants.COUNTER_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case messagesConstants.COUNTER_SAVE_SUCCESS:
      return {
        ...state,
        requestSaveActive: false,
      }
    case messagesConstants.COUNTER_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
