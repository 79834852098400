import { slotGroupsConstants } from './slotGroupsConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  error: '',
  query: "",
  inProgress: true,
});

export function slotsGroups(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case slotGroupsConstants.SLOT_GROUPS_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case slotGroupsConstants.SLOT_GROUPS_LIST_SUCCESS: {
      if(action.slotsGroups.rows && action.slotsGroups.rows.length > 0){
        action.slotsGroups.rows.map(item => { item.tableKey = item.id + "1" });
      }
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.slotsGroups.rows ? action.slotsGroups.rows : [],
        total: action.slotsGroups.total ? action.slotsGroups.total : 0,
        inProgress: false,
      };
    }
    case slotGroupsConstants.SLOT_GROUPS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case slotGroupsConstants.SLOT_GROUPS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case slotGroupsConstants.SLOT_GROUPS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.exactFields !== action.pagination.exactFields
        || state.exactValues !== action.pagination.exactValues
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        exactFields: action.pagination.exactFields,
        exactValues: action.pagination.exactValues,
        shouldReload
      };
    }

    case slotGroupsConstants.SLOT_GROUPS_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case slotGroupsConstants.SLOT_GROUPS_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.slot]
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(l => l.id === action.slot.id ? action.slot : l)
      }
    case slotGroupsConstants.SLOT_GROUPS_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
