import { jackpotHistoryConstants } from './jackpotHistoryConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  query: "",
  total: 0,
  error: '',
  startDate: null,
  endDate: null,
  timeRange: null,
  resourceId: null,
  exactFields: null,
  exactValues: null,
  inProgress: true,
  reloadButtonDisabled:false,
});

export function jackpotHistory(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case jackpotHistoryConstants.JACKPOT_HISTORY_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
        reloadButtonDisabled: true,
      };
    case jackpotHistoryConstants.JACKPOT_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.jackpotHistoryList.rows ? action.jackpotHistoryList.rows : [],
        total: action.jackpotHistoryList.total ? action.jackpotHistoryList.total : 0,
        inProgress: false,
        reloadButtonDisabled: false,
      };
    case jackpotHistoryConstants.JACKPOT_HISTORY_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
        reloadButtonDisabled: false,
      };
    case jackpotHistoryConstants.JACKPOT_HISTORY_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case jackpotHistoryConstants.JACKPOT_HISTORY_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || (action.pagination.startDate && action.pagination.endDate)
        || state.resourceId !== action.pagination.resourceId
        || state.exactFields !== action.pagination.exactFields
        || state.exactValues !== action.pagination.exactValues
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        resourceId: action.pagination.resourceId,
        exactFields: action.pagination.exactFields,
        exactValues: action.pagination.exactValues,
        shouldReload
      };
    }

    default:
      return state
  }
}
