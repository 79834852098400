export const blacklistsConstants = {
  BLACKLISTS_LIST_REQUEST: 'BLACKLISTS_LIST_REQUEST',
  BLACKLISTS_LIST_SUCCESS: 'BLACKLISTS_LIST_SUCCESS',
  BLACKLISTS_LIST_FAILURE: 'BLACKLISTS_LIST_FAILURE',
  BLACKLISTS_LIST_CHANGED: 'BLACKLISTS_LIST_CHANGED',
  BLACKLISTS_LIST_PAGINATION: 'BLACKLISTS_LIST_PAGINATION',

  BLACKLISTS_LOAD_REQUEST: 'BLACKLISTS_LOAD_REQUEST',
  BLACKLISTS_LOAD_SUCCESS: 'BLACKLISTS_LOAD_SUCCESS',
  BLACKLISTS_LOAD_FAILURE: 'BLACKLISTS_LOAD_FAILURE',

  BLACKLISTS_SAVE_REQUEST: 'BLACKLISTS_SAVE_REQUEST',
  BLACKLISTS_SAVE_SUCCESS: 'BLACKLISTS_SAVE_SUCCESS',
  BLACKLISTS_SAVE_FAILURE: 'BLACKLISTS_SAVE_FAILURE',
};
