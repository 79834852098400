export const paymentTransactionConstants = {
  PAYMENT_TRANSACTIONS_LIST_REQUEST: 'PAYMENT_TRANSACTIONS_LIST_REQUEST',
  PAYMENT_TRANSACTIONS_LIST_SUCCESS: 'PAYMENT_TRANSACTIONS_LIST_SUCCESS',
  PAYMENT_TRANSACTIONS_LIST_FAILURE: 'PAYMENT_TRANSACTIONS_LIST_FAILURE',
  PAYMENT_TRANSACTIONS_LIST_CHANGED: 'PAYMENT_TRANSACTIONS_LIST_CHANGED',
  PAYMENT_TRANSACTIONS_LIST_PAGINATION: 'PAYMENT_TRANSACTIONS_LIST_PAGINATION',

  PAYMENT_TRANSACTIONS_LOAD_REQUEST: 'PAYMENT_TRANSACTIONS_LOAD_REQUEST',
  PAYMENT_TRANSACTIONS_LOAD_SUCCESS: 'PAYMENT_TRANSACTIONS_LOAD_SUCCESS',
  PAYMENT_TRANSACTIONS_LOAD_FAILURE: 'PAYMENT_TRANSACTIONS_LOAD_FAILURE',

  PAYMENT_TRANSACTIONS_SAVE_REQUEST: 'PAYMENT_TRANSACTIONS_SAVE_REQUEST',
  PAYMENT_TRANSACTIONS_SAVE_SUCCESS: 'PAYMENT_TRANSACTIONS_SAVE_SUCCESS',
  PAYMENT_TRANSACTIONS_SAVE_FAILURE: 'PAYMENT_TRANSACTIONS_SAVE_FAILURE',
};
