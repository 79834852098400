import { playerScansConstants } from './playerScansConstants';
import {userConstants} from "../../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  logItems: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  startDate: null,
  endDate: null,
  total: 0,
  logTotal: 0,
  error: '',
  query: "",
  inProgress: true,
  location_id: null,
});

export function player_exclusion_playerScans(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case playerScansConstants.PLAYER_SCANS_LIST_REQUEST:
    case playerScansConstants.PLAYER_SCANS_LOAD_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        logTotal: 0,
        inProgress: true,
      };
    case playerScansConstants.PLAYER_SCANS_LIST_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.playerScans.rows ? action.playerScans.rows : [],
        total: action.playerScans.total ? action.playerScans.total : 0,
        inProgress: false,
      };
    }
    case playerScansConstants.PLAYER_SCANS_LOAD_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        logItems: action.playerScans.rows ? action.playerScans.rows : [],
        logTotal: action.playerScans.total ? action.playerScans.total : 0,
        inProgress: false,
      };
    }
    case playerScansConstants.PLAYER_SCANS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case playerScansConstants.PLAYER_SCANS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case playerScansConstants.PLAYER_SCANS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.startDate !== action.pagination.startDate
        || state.endDate !== action.pagination.endDate
        || state.location_id !== action.pagination.location_id
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        location_id: action.pagination.location_id,
        shouldReload
      };
    }

    case playerScansConstants.PLAYER_SCANS_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case playerScansConstants.PLAYER_SCANS_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.playerScans],
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(t => t.id === action.playerScans.id ? action.playerScans : t)
      }
    case playerScansConstants.PLAYER_SCANS_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }
    default:
      return state
  }
}
