export const locationGroupsConstants = {
  LOCATION_GROUPS_LIST_REQUEST: 'LOCATION_GROUPS_LIST_REQUEST',
  LOCATION_GROUPS_LIST_SUCCESS: 'LOCATION_GROUPS_LIST_SUCCESS',
  LOCATION_GROUPS_LIST_FAILURE: 'LOCATION_GROUPS_LIST_FAILURE',
  LOCATION_GROUPS_LIST_CHANGED: 'LOCATION_GROUPS_LIST_CHANGED',
  LOCATION_GROUPS_LIST_PAGINATION: 'LOCATION_GROUPS_LIST_PAGINATION',

  LOCATION_GROUPS_LOAD_REQUEST: 'LOCATION_GROUPS_LOAD_REQUEST',
  LOCATION_GROUPS_LOAD_SUCCESS: 'LOCATION_GROUPS_LOAD_SUCCESS',
  LOCATION_GROUPS_LOAD_FAILURE: 'LOCATION_GROUPS_LOAD_FAILURE',

  LOCATION_GROUPS_SAVE_REQUEST: 'LOCATION_GROUPS_SAVE_REQUEST',
  LOCATION_GROUPS_SAVE_SUCCESS: 'LOCATION_GROUPS_SAVE_SUCCESS',
  LOCATION_GROUPS_SAVE_FAILURE: 'LOCATION_GROUPS_SAVE_FAILURE',
};
