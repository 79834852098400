export const machineTypesConstants = {
  MACHINE_TYPES_LIST_REQUEST: 'MACHINE_TYPES_LIST_REQUEST',
  MACHINE_TYPES_LIST_SUCCESS: 'MACHINE_TYPES_LIST_SUCCESS',
  MACHINE_TYPES_LIST_FAILURE: 'MACHINE_TYPES_LIST_FAILURE',
  MACHINE_TYPES_LIST_CHANGED: 'MACHINE_TYPES_LIST_CHANGED',
  MACHINE_TYPES_LIST_PAGINATION: 'MACHINE_TYPES_LIST_PAGINATION',

  MACHINE_TYPES_LOAD_REQUEST: 'MACHINE_TYPES_LOAD_REQUEST',
  MACHINE_TYPES_LOAD_SUCCESS: 'MACHINE_TYPES_LOAD_SUCCESS',
  MACHINE_TYPES_LOAD_FAILURE: 'MACHINE_TYPES_LOAD_FAILURE',

  MACHINE_TYPES_SAVE_REQUEST: 'MACHINE_TYPES_SAVE_REQUEST',
  MACHINE_TYPES_SAVE_SUCCESS: 'MACHINE_TYPES_SAVE_SUCCESS',
  MACHINE_TYPES_SAVE_FAILURE: 'MACHINE_TYPES_SAVE_FAILURE',
};
