export const smibConstants = {
  SMIB_LIST_REQUEST: 'SMIB_LIST_REQUEST',
  SMIB_LIST_SUCCESS: 'SMIB_LIST_SUCCESS',
  SMIB_LIST_FAILURE: 'SMIB_LIST_FAILURE',
  SMIB_LIST_CHANGED: 'SMIB_LIST_CHANGED',
  SMIB_LIST_PAGINATION: 'SMIB_LIST_PAGINATION',

  SMIB_LOAD_REQUEST: 'SMIB_LOAD_REQUEST',
  SMIB_LOAD_SUCCESS: 'SMIB_LOAD_SUCCESS',
  SMIB_LOAD_FAILURE: 'SMIB_LOAD_FAILURE',

  SMIB_SAVE_REQUEST: 'SMIB_SAVE_REQUEST',
  SMIB_SAVE_SUCCESS: 'SMIB_SAVE_SUCCESS',
  SMIB_SAVE_FAILURE: 'SMIB_SAVE_FAILURE',
  SMIB_DONE_SUCCESS: 'SMIB_DONE_SUCCESS',

  //SMIB Commands
  SMIB_RESTART_NOBT: 1, //restart smib 
  SMIB_RESTART_WBT: 2, //restart smib enter bootloader
};
