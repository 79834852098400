import { currenttaskConstants } from './currentTaskConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "create_time",
  sortDirection: "desc",
  total: 0,
  error: '',
  inProgress: true,
});

export function currenttasks(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case currenttaskConstants.CURRENT_TASK_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case currenttaskConstants.CURRENT_TASK_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.currenttasks.rows ? action.currenttasks.rows : [],
        total: action.currenttasks.total ? action.currenttasks.total : 0,
        inProgress: false,
      };
    case currenttaskConstants.CURRENT_TASK_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case currenttaskConstants.CURRENT_TASK_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case currenttaskConstants.CURRENT_TASK_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        shouldReload
      };
    }

    default:
      return state
  }
}
