/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import newtonLogo from "../../../../assets/images/newton_logo.svg";

export function FooterExtended({
  today,
  footerClasses,
  footerContainerClasses,
}) {
  const bgStyle = toAbsoluteUrl("/media/bg/bg-2.jpg");
  return (
    <>
      {/* begin::Footer */}
      <div
        className={`footer ${footerClasses} kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover`}
        id="kt_footer"
        style={{ backgroundImage: `url("${bgStyle}")` }}
      >
        {/* begin::Container */}
        <div className="separator separator-solid opacity-7"></div>
        {/* end::Container */}

        {/* begin::Container */}
        <div className={`${footerContainerClasses} py-8`}>
          <div className="d-flex align-items-center justify-content-between flex-lg-row flex-column">
            {/* begin::Copyright */}
            <div className="d-flex align-items-center order-lg-1 order-2">
              <img
                className="max-h-35px"
                alt="Logo"
                src={newtonLogo}
              />
              <span className="text-muted font-weight-bold mx-2">
                {today} ©
              </span>

            </div>
            {/* end::Copyright */}
          </div>
        </div>
        {/* end::Container */}
      </div>
      {/* end:Footer */}
    </>
  );
}
