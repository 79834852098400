import { messageMetersConstants } from './messageMetersConstants';
import { userConstants } from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  total: 0,
  error: '',
  smibid: '',
  dateStart: null,
  dateEnd: null,
  timeRange: null,
  query: '',
  type: 'tenant',
  id: null,
  meta: [],
  inProgress: true,
});

export function messageMeters(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case messageMetersConstants.MESSAGE_METERS_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case messageMetersConstants.MESSAGE_METERS_LIST_SUCCESS: {
      let messageMeters = [];
      // console.log("LOGS", action.messageMeters.meter_ids, action.messageMeters.meters);

      action.messageMeters.meters && action.messageMeters.meters.map((meter, index) => {
        const updatedMeter = { ...meter };
        updatedMeter.columns = [];
        action.messageMeters.meter_ids && action.messageMeters.meter_ids.map((meter_id, i) => {
          let meters_float = meter.meters_float && meter.meters_float.find(i => i.id === meter_id);
          if(meters_float){
            if(meters_float.value){
              updatedMeter.columns[i] = { title: meter_id, value: meters_float.details ? `${meters_float.value} (${meters_float.details})` : meters_float.value };
            }else{
              updatedMeter.columns[i] = { title: meter_id, value: meters_float.details ? `0 (${meters_float.details})` : 0 };
            }
          }else{
            updatedMeter.columns[i] = { title: meter_id, value: '-' };
          }

          let meters_uint = meter.meters_uint && meter.meters_uint.find(i => i.id === meter_id);
          if(meters_uint){
            if(meters_uint.value){
              updatedMeter.columns[i] = { title: meter_id, value: meters_uint.details ? `${meters_uint.value} (${meters_uint.details})` : meters_uint.value };
            }else{
              updatedMeter.columns[i] = { title: meter_id, value: meters_uint.details ? `0 (${meters_uint.details})` : 0 };
            }
          }else{
            updatedMeter.columns[i] = { title: meter_id, value: "-" };
          }
        });
        updatedMeter.line_id = index;
        messageMeters.push(updatedMeter);
      });

      // console.log("LOGS", messageMeters);


      let items = [];
      items['meter_ids'] = action.messageMeters.meter_ids;
      items['meters'] = messageMeters;

      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: items,
        total: action.messageMeters.total ? action.messageMeters.total : 0,
        inProgress: false,
      };
    }
    case messageMetersConstants.MESSAGE_METERS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case messageMetersConstants.MESSAGE_METERS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case messageMetersConstants.MESSAGE_METERS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.dateStart !== action.pagination.dateStart
        || state.dateEnd !== action.pagination.dateEnd
        || state.query !== action.pagination.query
        || state.type !== action.pagination.type
        || state.id !== action.pagination.id
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        smibid: action.pagination.smibid,
        dateStart: action.pagination.dateStart,
        dateEnd: action.pagination.dateEnd,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        show_all: action.pagination.show_all,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        type: action.pagination.type,
        id: action.pagination.id,
        shouldReload
      };
    }

    case messageMetersConstants.COUNTER_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case messageMetersConstants.COUNTER_SAVE_SUCCESS:
      return {
        ...state,
        requestSaveActive: false,
      }
    case messageMetersConstants.COUNTER_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
