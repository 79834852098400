export const selfExclusionsConstants = {
  SELF_EXCLUSIONS_LIST_REQUEST: 'SELF_EXCLUSIONS_LIST_REQUEST',
  SELF_EXCLUSIONS_LIST_SUCCESS: 'SELF_EXCLUSIONS_LIST_SUCCESS',
  SELF_EXCLUSIONS_LIST_FAILURE: 'SELF_EXCLUSIONS_LIST_FAILURE',
  SELF_EXCLUSIONS_LIST_CHANGED: 'SELF_EXCLUSIONS_LIST_CHANGED',
  SELF_EXCLUSIONS_LIST_PAGINATION: 'SELF_EXCLUSIONS_LIST_PAGINATION',

  SELF_EXCLUSIONS_LOAD_REQUEST: 'SELF_EXCLUSIONS_LOAD_REQUEST',
  SELF_EXCLUSIONS_LOAD_SUCCESS: 'SELF_EXCLUSIONS_LOAD_SUCCESS',
  SELF_EXCLUSIONS_LOAD_FAILURE: 'SELF_EXCLUSIONS_LOAD_FAILURE',

  SELF_EXCLUSIONS_SAVE_REQUEST: 'SELF_EXCLUSIONS_SAVE_REQUEST',
  SELF_EXCLUSIONS_SAVE_SUCCESS: 'SELF_EXCLUSIONS_SAVE_SUCCESS',
  SELF_EXCLUSIONS_SAVE_FAILURE: 'SELF_EXCLUSIONS_SAVE_FAILURE',
};
