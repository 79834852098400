export const counterConstants = {
  COUNTER_LIST_REQUEST: 'COUNTER_LIST_REQUEST',
  COUNTER_LIST_SUCCESS: 'COUNTER_LIST_SUCCESS',
  COUNTER_LIST_FAILURE: 'COUNTER_LIST_FAILURE',
  COUNTER_LIST_CHANGED: 'COUNTER_LIST_CHANGED',
  COUNTER_LIST_PAGINATION: 'COUNTER_LIST_PAGINATION',

  COUNTER_LOAD_REQUEST: 'COUNTER_LOAD_REQUEST',
  COUNTER_LOAD_SUCCESS: 'COUNTER_LOAD_SUCCESS',
  COUNTER_LOAD_FAILURE: 'COUNTER_LOAD_FAILURE',

  COUNTER_SAVE_REQUEST: 'COUNTER_SAVE_REQUEST',
  COUNTER_SAVE_SUCCESS: 'COUNTER_SAVE_SUCCESS',
  COUNTER_SAVE_FAILURE: 'COUNTER_SAVE_FAILURE',
};
