import React, { Fragment, useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import KTLayoutHeaderMenu from "../../../../_assets/js/layout/base/header-menu";

import {
  HeaderMenuHome,
  HeaderMenuMonitoring,
  HeaderMenuJackpots,
  HeaderMenuReports,
  HeaderMenuAdvanced,
  HeaderMenuACL,
  HeaderMenuAdmin,
  HeaderMenuOperations,
  HeaderMenuNotifications,
  HeaderMenuPlayerExclusion,
  HeaderMenuGameRegistry
} from "./HeaderMenu";
import { useTranslation } from "react-i18next";
import { hasMainMenuAccess } from "../MenuAccess";

export function HeaderMenuWrapper(props) {
  const { activeMenu, tabClick } = props;
  const { t } = useTranslation();

  //console.log("activeMenu", activeMenu, props);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      ktMenuClasses: uiService.getClasses("header_menu", true),
      rootArrowEnabled: objectPath.get(
        uiService.config,
        "header.menu.self.root-arrow"
      ),
      menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
      headerMenuAttributes: uiService.getAttributes("header_menu"),
      headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
      ulClasses: uiService.getClasses("header_menu_nav", true),
      disabledAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false
    };
  }, [uiService]);

  React.useEffect(() => {
    setTimeout(() => {
      KTLayoutHeaderMenu.init('kt_header_menu', 'kt_header_menu_wrapper');
    }, 200);
  }, [activeMenu]);

  return <>
    <Fragment>
      <div className="header-navs header-navs-left header-menu-mobile header-menu-layout-default d-lg-none" id="kt_header_menu_wrapper">
        <ul className="menu-nav">
          {hasMainMenuAccess("DASHBOARD") && (
            <li onClick={tabClick(0)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 0 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Home")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuHome layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("MONITORING") && (
            <li onClick={tabClick(1)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 1 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Monitoring")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuMonitoring layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("JACKPOTS") && (
            <li onClick={tabClick(2)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 2 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Jackpots")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuJackpots layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("REPORTS") && (
            <li onClick={tabClick(3)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 3 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Reports")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuReports layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("ADVANCED") && (
            <li onClick={tabClick(4)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 4 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Advanced")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuAdvanced layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("ACL") && (
            <li onClick={tabClick(5)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 5 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Access Control List")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuACL layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("ADMIN") && (
            <li onClick={tabClick(6)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 6 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Admin")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuAdmin layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("OPERATIONS") && (
            <li onClick={tabClick(7)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 7 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Operations")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuOperations layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("NOTIFICATIONS") && (
            <li onClick={tabClick(8)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 8 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Notifications")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuNotifications layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("PLAYER_EXCLUSION") && (
            <li onClick={tabClick(9)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 9 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Player Exclusion")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuPlayerExclusion layoutProps={layoutProps} />
            </li>
          )}
          {hasMainMenuAccess("GAMES_REGISTRY") && (
            <li onClick={tabClick(10)} className={`menu-item menu-item-submenu menu-item-rel ${activeMenu === 10 ? 'menu-item-open' : ''}`}>
              <a className={`menu-link menu-toggle`}>
                <span className="menu-text">{t("Games Registry")}</span>
                <i className="menu-arrow"></i>
              </a>
              <HeaderMenuPlayerExclusion layoutProps={layoutProps} />
            </li>
          )}
        </ul>
      </div>
      <div className="header-navs header-navs-left d-none d-lg-flex" id="kt_header_menu_wrapper">
        {activeMenu === 0 && <HeaderMenuHome layoutProps={layoutProps} />}
        {activeMenu === 1 && <HeaderMenuMonitoring layoutProps={layoutProps} />}
        {activeMenu === 2 && <HeaderMenuJackpots layoutProps={layoutProps} />}
        {activeMenu === 3 && <HeaderMenuReports layoutProps={layoutProps} />}
        {activeMenu === 4 && <HeaderMenuAdvanced layoutProps={layoutProps} />}
        {activeMenu === 5 && <HeaderMenuACL layoutProps={layoutProps} />}
        {activeMenu === 6 && <HeaderMenuAdmin layoutProps={layoutProps} />}
        {activeMenu === 7 && <HeaderMenuOperations layoutProps={layoutProps} />}
        {activeMenu === 8 && <HeaderMenuNotifications layoutProps={layoutProps} />}
        {activeMenu === 9 && <HeaderMenuPlayerExclusion layoutProps={layoutProps} />}
        {activeMenu === 10 && <HeaderMenuGameRegistry layoutProps={layoutProps} />}
      </div>
    </Fragment>
  </>
}
