export const messageMetersConstants = {
  MESSAGE_METERS_LIST_REQUEST: 'MESSAGE_METERS_LIST_REQUEST',
  MESSAGE_METERS_LIST_SUCCESS: 'MESSAGE_METERS_LIST_SUCCESS',
  MESSAGE_METERS_LIST_FAILURE: 'MESSAGE_METERS_LIST_FAILURE',
  MESSAGE_METERS_LIST_CHANGED: 'MESSAGE_METERS_LIST_CHANGED',
  MESSAGE_METERS_LIST_PAGINATION: 'MESSAGE_METERS_LIST_PAGINATION',

  MESSAGE_METERS_LOAD_REQUEST: 'MESSAGE_METERS_LOAD_REQUEST',
  MESSAGE_METERS_LOAD_SUCCESS: 'MESSAGE_METERS_LOAD_SUCCESS',
  MESSAGE_METERS_LOAD_FAILURE: 'MESSAGE_METERS_LOAD_FAILURE',

  MESSAGE_METERS_SAVE_REQUEST: 'MESSAGE_METERS_SAVE_REQUEST',
  MESSAGE_METERS_SAVE_SUCCESS: 'MESSAGE_METERS_SAVE_SUCCESS',
  MESSAGE_METERS_SAVE_FAILURE: 'MESSAGE_METERS_SAVE_FAILURE',
};