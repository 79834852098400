//import openSocket from "socket.io-client";
import { io } from "socket.io-client";
import { endpoints } from "../api/endpoints";
import { store } from "../store";
import { getAccessToken } from "./authAxios";
import { rtNotificationReceived } from "../store/actions/rt_notifications";
import { refreshToken } from "./authAxios";

const DEBUG = false;
const AUTH_RETRY_INTERVAL = 10000; // 10 seconds

let socket = null;

export const websocketConnect = () => {
    if (socket !== null) {
        return;
    }

    DEBUG && console.log("connect websocket to", endpoints.websocket.host, endpoints.websocket.path);

    const query = {
        //protocol: "sio1"
    };

    socket = io(endpoints.websocket.host, {
        path: endpoints.websocket.path,
        forceNew: true,
        transports: ["websocket"],
        query,
    });

    socket.on("connect", () => {
        DEBUG && console.log("websocket connected, maybe authenticate");

        const state = store.getState();
        const { auth } = state;

        DEBUG && console.log("auth", auth);

        websocketAuth(auth);
    });

    socket.on("notification", data => {
        DEBUG && console.log("received notification", data);
        store.dispatch(rtNotificationReceived(data));
    });
};

export const websocketAuth = auth => {
    DEBUG && console.log("websocket authentication called");

    if (socket === null) {
        websocketConnect();
        return;
    }

    DEBUG && console.log("auth", auth);

    if (auth.loggedIn) {
        socket.emit(
            "login",
            {
                token: "Bearer " + getAccessToken(),
            },
            res => {
                DEBUG && console.log("websocket auth result", res);

                if (res !== "ok") {
                    if (res === "unauthorized") {
                        DEBUG && console.log("websocket auth got unauthorized response. refresh and try again");

                        setTimeout(
                            () =>
                                refreshToken()
                                    .then(() => websocketAuth(auth))
                                    .catch(e => console.error(`token refresh failed`, e)),
                            AUTH_RETRY_INTERVAL
                        );
                    } else {
                        DEBUG && console.log("websocket auth failed. retry in", AUTH_RETRY_INTERVAL);

                        setTimeout(() => websocketAuth(auth), AUTH_RETRY_INTERVAL);
                    }
                }
            }
        );
    }
};

export const websocketDeauth = () => {
    DEBUG && console.log("websocket deauthentication called");

    if (socket === null) {
        return;
    }

    socket.emit("logout");
};
