import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute, ContentInnerPageRoute, ContentVariantInnerPageRoute } from "../_metronic/layout";

import UserProfilePage from "../views/user/UserProfilePage";
import { routes } from '../router';

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/access" />
        {routes.map((route, i) => {
          if (route.variant) {
            return <ContentVariantInnerPageRoute key={i} {...route} />;
          }
          return <ContentInnerPageRoute key={i} {...route} />;
        })}
        <Route path="/user-profile" component={UserProfilePage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
