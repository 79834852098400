export const protocolsConstants = {
  PROTOCOLS_LIST_REQUEST: 'PROTOCOLS_LIST_REQUEST',
  PROTOCOLS_LIST_SUCCESS: 'PROTOCOLS_LIST_SUCCESS',
  PROTOCOLS_LIST_FAILURE: 'PROTOCOLS_LIST_FAILURE',
  PROTOCOLS_LIST_CHANGED: 'PROTOCOLS_LIST_CHANGED',
  PROTOCOLS_LIST_PAGINATION: 'PROTOCOLS_LIST_PAGINATION',

  PROTOCOLS_LOAD_REQUEST: 'PROTOCOLS_LOAD_REQUEST',
  PROTOCOLS_LOAD_SUCCESS: 'PROTOCOLS_LOAD_SUCCESS',
  PROTOCOLS_LOAD_FAILURE: 'PROTOCOLS_LOAD_FAILURE',

  PROTOCOLS_SAVE_REQUEST: 'PROTOCOLS_SAVE_REQUEST',
  PROTOCOLS_SAVE_SUCCESS: 'PROTOCOLS_SAVE_SUCCESS',
  PROTOCOLS_SAVE_FAILURE: 'PROTOCOLS_SAVE_FAILURE',
};
