import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Content } from "./Content";
import { hasSubMenuAccess } from "../header/MenuAccess";
import { getCurrentUrl } from "../../../_helpers";
import { routes } from "../../../../router";

export function ContentVariantInnerPageRoute({ children, component, render, ...props }) {

  let history = useHistory();

  if (props.path === getCurrentUrl(props.location) && !hasSubMenuAccess(props.path)) {
    let index = 0;
    while (index < routes.length ) {
      if(hasSubMenuAccess(routes[index].path)){
        history.push(routes[index].path);
        break;
      }
      index++;
    }
  }

  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}

