const customLayoutRoutes = [
    "/reports/daily-report/:id",
    "/reports/daily-report/:id/:exportType",
    "/reports/daily-jackpot-report/:id",
    "/reports/daily-jackpot-report/:id/:exportType",
    "/reports/monthly-report/:locationIdYearMonth",
    "/reports/monthly-report/:locationIdYearMonth/:exportType",
    "/reports/monthly-jackpot-report/:locationIdYearMonth",
    "/reports/monthly-jackpot-report/:locationIdYearMonth/:exportType",
];

const getFormattedPath = () => {
    let path = window.location.pathname.split('/');

    customLayoutRoutes.find(item => {
        let custom = item.split('/');
        if (path.length === custom.length) {
            custom.map((c, i) => {
                if (c.includes(':')) {
                    path[i] = c;
                }
            })
            return path.join('/') === custom.join('/');
        }
    })

    return path.join('/');
}

export const isCustomLayout = () => {
    let path = getFormattedPath();
    return customLayoutRoutes.find(r => path === r && path.split('/').length === r.split('/').length);
}