import { hasPermission } from "../../../../shared/permissionCheck";

export const hasMainMenuAccess = (page) => {
  switch (page) {
    case "DASHBOARD":
      return hasSubMenuAccess("/dashboard");
    case "MONITORING":
      return (
        hasSubMenuAccess("/tenants") ||
        hasSubMenuAccess("/locations") ||
        hasSubMenuAccess("/slots") ||
        hasSubMenuAccess("/slots-demo") ||
        hasSubMenuAccess("/events") ||
        hasSubMenuAccess("/entity-groups")
      );
    case "JACKPOTS":
      return (
        hasSubMenuAccess("/jackpots") ||
        hasSubMenuAccess("/jackpot-history") ||
        hasSubMenuAccess("/jackpot-sources") ||
        hasSubMenuAccess("/display-channels")
      );
    case "REPORTS":
      return (
        hasSubMenuAccess("/reports/slot-games") ||
        hasSubMenuAccess("/reports/daily-reports") ||
        hasSubMenuAccess("/reports/monthly-reports") ||
        hasSubMenuAccess("/reports/payment-transactions") ||
        hasSubMenuAccess("/reports/collector-checkpoints")
      );
    case "ADVANCED":
      return (
        hasSubMenuAccess("/advanced/smibs") ||
        hasSubMenuAccess("/advanced/checkpoints") ||
        hasSubMenuAccess("/advanced/timelog") ||
        hasSubMenuAccess("/advanced/tasks") ||
        hasSubMenuAccess("/advanced/aggregation-rules")
      );
    case "ACL":
      return (
        hasSubMenuAccess("/acl/users") ||
        hasSubMenuAccess("/acl/user-groups") ||
        hasSubMenuAccess("/acl/permissions") ||
        hasSubMenuAccess("/acl/roles") ||
        hasSubMenuAccess("/acl/entity-groups") ||
        hasSubMenuAccess("/acl/rules") ||
        hasSubMenuAccess("/acl/api-tokens")
      );
    case "ADMIN":
      return (
        hasSubMenuAccess("/admin/columns-configurations") ||
        hasSubMenuAccess("/admin/general-settings")
      );
    case "OPERATIONS":
      return hasSubMenuAccess("/operations/remote-cashout");
    case "NOTIFICATIONS":
      return (
        hasSubMenuAccess("/notifications/list") ||
        hasSubMenuAccess("/notifications/subscriptions")
      );
    case "PLAYER_EXCLUSION":
      return (
        hasSubMenuAccess("/player-exclusion/players") ||
        hasSubMenuAccess("/player-exclusion/self-exclusions") ||
        hasSubMenuAccess("/player-exclusion/player-scans") ||
        hasSubMenuAccess("/player-exclusion/blacklists")
      );
    case "GAMES_REGISTRY":
      return (
        hasSubMenuAccess("/games-registry/machine-types") ||
        hasSubMenuAccess("/games-registry/games") ||
        hasSubMenuAccess("/games-registry/game-mixes") ||
        hasSubMenuAccess("/games-registry/game-profiles") ||
        hasSubMenuAccess("/games-registry/manufacturers") ||
        hasSubMenuAccess("/games-registry/protocols")
      );
    default:
      return false;
  }
}

export const hasSubMenuAccess = (page) => {
  switch (page) {
    // =============== DASHBOARD ===============
    case "/dashboard": return (
      hasPermission("dashboard_settings::get") &&
      hasPermission("general_settings::get") &&
      hasPermission("location::get_general_settings") &&
      hasPermission("tenant::get_general_settings") &&
      hasPermission("tenant::list") &&
      hasPermission("location::list")
    );

    // =============== MONITORING ===============
    case "/tenants": return (
      hasPermission("tenant::list")
    );
    case "/locations": return (
      hasPermission("location::list")
    );
    case "/slots": return (
      hasPermission("slot::list")
    );
    case "/slots-demo": return (
      hasPermission("slot::list")
    );
    case "/events": return (
      hasPermission("slot::get")
    );
    case "/entity-groups": return (
      hasPermission("tenant_entity_group::list") &&
      hasPermission("location_entity_group::list") &&
      hasPermission("slot_entity_group::list")
    );
    case "/message-meters": return (
      hasPermission("slot::get")
    );
    case "/reports/extended-meters": return (
      hasPermission("slot::get")
    );

    // =============== JACKPOTS ===============
    case "/jackpots": return (
      hasPermission("jackpot::list")
    );
    case "/jackpot-history": return (
      hasPermission("jackpot::list") &&
      hasPermission("jackpot_win::list")
    );
    case "/jackpot-sources": return (
      hasPermission("jackpot_source::list")
    );
    case "/display-channels": return (
      hasPermission("jackpot::list") &&
      hasPermission("display_channel::list")
    );

    // =============== REPORTS ===============
    case "/reports/slot-games": return (
      hasPermission("slot::get") &&
      hasPermission("slot::update") &&
      hasPermission("slot::archive")
    );
    case "/reports/daily-reports": return (
      hasPermission("location::list") &&
      hasPermission("location::list_fiscal_reports")
    );
    case "/reports/monthly-reports": return (
      hasPermission("location::list") &&
      hasPermission("location::list_monthly_fiscal_reports")
    );
    case "/reports/payment-transactions": return (
      hasPermission("slot::get_payment_transactions")
    );
    case "/reports/collector-checkpoints": return (
      hasPermission("collector_checkpoint::list_collector_checkpoints")
    );

    // =============== ADVANCED ===============
    case "/advanced/smibs": return (
      hasPermission("smib::list")
    );
    case "/advanced/checkpoints": return (
      hasPermission("smib::get_checkpoint") &&
      hasPermission("slot::get_checkpoint") &&
      hasPermission("location::get_checkpoint") &&
      hasPermission("tenant::get_checkpoint")
    );
    case "/advanced/timelog": return (
      hasPermission("smib::get_timelog") &&
      hasPermission("slot::get_timelog") &&
      hasPermission("location::get_timelog") &&
      hasPermission("tenant::get_timelog")
    );
    case "/advanced/tasks": return (
      hasPermission("task_current::list") &&
      hasPermission("task_completed::list")
    );
    case "/advanced/aggregation-rules": return (
      hasPermission("aggregation_rule::get") &&
      hasPermission("aggregation_rule::update") &&
      hasPermission("aggregation_rule::create")
    );

    // =============== ACL ===============
    case "/acl/users": return (
      hasPermission("acl_user::list")
    );
    case "/acl/user-groups": return (
      hasPermission("acl_user_group::list")
    );
    case "/acl/permissions": return (
      hasPermission("acl_permission::list")
    );
    case "/acl/roles": return (
      hasPermission("acl_role::list")
    );
    case "/acl/entity-groups": return (
      hasPermission("acl_entity_group::list")
    );
    case "/acl/rules": return (
      hasPermission("acl_rule::list")
    );
    case "/acl/api-tokens": return (
      hasPermission("api_token::list")
    );

    // =============== ADMIN ===============
    case "/admin/columns-configurations": return (
      hasPermission("columns_configuration::get_system_all_columns")
    );
    case "/admin/general-settings": return (
      hasPermission("general_settings::get")
    );

    // =============== OPERATIONS ===============
    case "/operations/remote-cashout": return (
      hasPermission("slot::remote_cashout_list")
    );

    // =============== NOTIFICATIONS ===============
    case "/notifications/list": return true;
    case "/notifications/subscriptions": return true;

    // =============== PLAYER_EXCLUSION ===============
    case "/player-exclusion/players": return (
      hasPermission("pe_player::list_players")
    );
    case "/player-exclusion/self-exclusions": return (
      hasPermission("pe_exclusion::list_self_exclusions")
    );
    case "/player-exclusion/player-scans": return (
      hasPermission("pe_scan::list_player_scans")
    );
    case "/player-exclusion/blacklists": return (
      hasPermission("pe_blacklist::list_blacklists")
    );

    // =============== GAMES_REGISTRY ===============
    case "/games-registry/machine-types": return (
      hasPermission("gr_machine_type::list_machine_types")
    );
    case "/games-registry/games": return (
      hasPermission("gr_game::list_games")
    );
    case "/games-registry/game-mixes": return (
      hasPermission("gr_game_mix::list_game_mixes")
    );
    case "/games-registry/game-profiles": return (
      hasPermission("gr_game_profile::list_game_profiles")
    );
    case "/games-registry/manufacturers": return (
      hasPermission("gr_manufacturer::list_manufacturers")
    );
    case "/games-registry/protocols": return (
      hasPermission("gr_protocol::list_protocols")
    );

    default: return false;
  }
}
