import { timelogConstants } from './timelogConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  total: 0,
  error: '',
  start_time: null,
  end_time: null,
  type: '',
  resourceId: '',
  timeRange: null,
  inProgress: true,
});

export function timelog(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case timelogConstants.TIMELOG_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case timelogConstants.TIMELOG_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.timelog.rows ? action.timelog.rows : [],
        total: action.timelog.total ? action.timelog.total : 0,
        inProgress: false,
      };
    case timelogConstants.TIMELOG_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        requestActive: false,
        inProgress: false,
        items: [],
      };
    case timelogConstants.TIMELOG_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case timelogConstants.TIMELOG_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.shouldReload
        || action.pagination.type
        || action.pagination.resourceId
        || (action.pagination.start_time && action.pagination.end_time)) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        start_time: action.pagination.start_time,
        end_time: action.pagination.end_time,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        type: action.pagination.type,
        resourceId: action.pagination.resourceId,
        shouldReload
      };
    }

    case timelogConstants.TIMELOG_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case timelogConstants.TIMELOG_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.timelog]
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(l => l.id === action.timelog.id ? action.timelog : l)
      }
    case timelogConstants.TIMELOG_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
