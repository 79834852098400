import { checkpointConstants } from './checkpointConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  total: 0,
  error: '',
  startDate: null,
  endDate: null,
  timeRange: null,
  type: '',
  resourceId: '',
  inProgress: true,
});

export function checkpoints(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case checkpointConstants.CHECKPOINT_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case checkpointConstants.CHECKPOINT_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.checkpoints.rows ? action.checkpoints.rows : [],
        total: action.checkpoints.total ? action.checkpoints.total : 0,
        inProgress: false,
      };
    case checkpointConstants.CHECKPOINT_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case checkpointConstants.CHECKPOINT_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case checkpointConstants.CHECKPOINT_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.shouldReload
        || action.pagination.type
        || action.pagination.resourceId
        || (action.pagination.startDate && action.pagination.endDate)) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        type: action.pagination.type,
        resourceId: action.pagination.resourceId,
        shouldReload
      };
    }

    case checkpointConstants.CHECKPOINT_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case checkpointConstants.CHECKPOINT_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.location]
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(l => l.id === action.checkpoint.id ? action.checkpoint : l)
      }
    case checkpointConstants.CHECKPOINT_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
