import { editsConstants } from '../constants/edits';
import {userConstants} from "../../views/auth/_redux/authConstants";

const makeInitialState = () => ({
  items: {
    events: [],
    counters: [],
    slotdata: [],
  },
});

export function edits(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case editsConstants.EDITS_LIST_ADD:
    case editsConstants.EDITS_LIST_CHANGE: {
      const currentState = { ...state };
      const { type, elem } = action.op;

      let index = -1;
      currentState.items[type].forEach((event, i) => {
        if (event._key === elem._key) {
          index = i;
        }
      });
      if (index > -1) {
        currentState.items[type][index] = elem;
      } else {
        currentState.items[type].push(elem);
      }

      return currentState;
    }
    case editsConstants.EDITS_LIST_REMOVE: {
      const currentState = { ...state };
      const { type, elem } = action.op;

      currentState.items[type] = currentState.items[type].filter(item => item._key !== elem._key);
      return currentState;
    }
    case editsConstants.EDITS_LIST_CLEAR: {
      const currentState = { ...state };
      const { type } = action.op;
      currentState.items[type] = [];
      return currentState;
    }
    default:
      return state
  }
}
