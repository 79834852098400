import { protocolsConstants } from './protocolsConstants';
import {userConstants} from "../../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  logItems: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  startDate: null,
  endDate: null,
  total: 0,
  logTotal: 0,
  error: '',
  query: "",
  inProgress: true,
});

export function games_registry_protocols(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case protocolsConstants.PROTOCOLS_LIST_REQUEST:
    case protocolsConstants.PROTOCOLS_LOAD_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        logTotal: 0,
        inProgress: true,
      };
    case protocolsConstants.PROTOCOLS_LIST_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.protocols.rows ? action.protocols.rows : [],
        total: action.protocols.total ? action.protocols.total : 0,
        inProgress: false,
      };
    }
    case protocolsConstants.PROTOCOLS_LOAD_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        logItems: action.protocols.rows ? action.protocols.rows : [],
        logTotal: action.protocols.total ? action.protocols.total : 0,
        inProgress: false,
      };
    }
    case protocolsConstants.PROTOCOLS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case protocolsConstants.PROTOCOLS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case protocolsConstants.PROTOCOLS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.query !== action.pagination.query
        || state.startDate !== action.pagination.startDate
        || state.endDate !== action.pagination.endDate
        || state.exactValues !== action.pagination.exactValues
        || state.exactFields !== action.pagination.exactFields
        || state.shouldReload) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        query: action.pagination.query,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        exactValues: action.pagination.exactValues,
        exactFields: action.pagination.exactFields,
        shouldReload
      };
    }

    case protocolsConstants.PROTOCOLS_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case protocolsConstants.PROTOCOLS_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          items: [...state.items, action.protocols],
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        items: state.items.map(t => t.id === action.protocols.id ? action.protocols : t)
      }
    case protocolsConstants.PROTOCOLS_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }
    default:
      return state
  }
}
