import moment from "moment";

import { appConstants } from "../constants/app";

const DEBUG = false;

const parseBuildInfo = bi => {
    const commit_date = moment(bi.commit_date);
    //const build_date = moment(bi.build_date);

    return {
        commit: bi.commit,
        commit_date,
        //build_date
    }
}

const makeInitialState = () => {
    let thisVersion = null;
    if (process.env.REACT_APP_BUILD_INFO) {
        thisVersion = parseBuildInfo(JSON.parse(process.env.REACT_APP_BUILD_INFO));
    }

    //thisVersion = parseBuildInfo(JSON.parse(`{"commit":"v0-99a-g350432f", "commit_date":"2021-09-30T17:48:00+03:00", "build_date":"2021-09-30T14:51:37+0000"}`));

    return {
        thisVersion,
        remoteVersion: null,
        newVersionAvailable: false,
        versionModalShown: false,
    };
};

export function app(state = makeInitialState(), action) {
    DEBUG && console.log("action = ", action);

    switch (action.type) {
        case appConstants.SHOW_VERSION_CHECK_MODAL: {
            return {
                ...state,
                versionModalShown: true,
            };
        }
        case appConstants.HIDE_VERSION_CHECK_MODAL: {
            return {
                ...state,
                versionModalShown: false,
            };
        }
        case appConstants.BUILD_INFO_RECEIVED: {
            // check current build info
            if (!state.thisVersion) {
                DEBUG && console.log("no current version");
                return state;
            }

            // check if there is a new version
            if (state.thisVersion.commit === action.buildInfo.commit) {
                DEBUG && console.log("current version and remote version match");
                return state;
            }

            // check remote build info received and it's the same
            if (state.remoteVersion && state.remoteVersion.commit == action.buildInfo.commit) {
                DEBUG && console.log("same remote version version");
                return state;
            }

            DEBUG && console.log("open modal with new version", state.thisVersion, action.buildInfo);

            // set new build info and open modal
            return {
                ...state,
                remoteVersion: parseBuildInfo(action.buildInfo),
                newVersionAvailable: state.thisVersion.commit != action.buildInfo.commit,
                versionModalShown: true,
            }
        }
        default:
            return state;
    }
}
