export const aggregationRuleConstants = {
  ADMIN_AGGREGATION_RULE_LIST_REQUEST: 'ADMIN_AGGREGATION_RULE_LIST_REQUEST',
  ADMIN_AGGREGATION_RULE_LIST_SUCCESS: 'ADMIN_AGGREGATION_RULE_LIST_SUCCESS',
  ADMIN_AGGREGATION_RULE_LIST_FAILURE: 'ADMIN_AGGREGATION_RULE_LIST_FAILURE',
  ADMIN_AGGREGATION_RULE_LIST_CHANGED: 'ADMIN_AGGREGATION_RULE_LIST_CHANGED',
  ADMIN_AGGREGATION_RULE_LIST_PAGINATION: 'ADMIN_AGGREGATION_RULE_LIST_PAGINATION',

  ADMIN_AGGREGATION_RULE_LOAD_REQUEST: 'ADMIN_AGGREGATION_RULE_LOAD_REQUEST',
  ADMIN_AGGREGATION_RULE_LOAD_SUCCESS: 'ADMIN_AGGREGATION_RULE_LOAD_SUCCESS',
  ADMIN_AGGREGATION_RULE_LOAD_FAILURE: 'ADMIN_AGGREGATION_RULE_LOAD_FAILURE',

  ADMIN_AGGREGATION_RULE_SAVE_REQUEST: 'ADMIN_AGGREGATION_RULE_SAVE_REQUEST',
  ADMIN_AGGREGATION_RULE_SAVE_SUCCESS: 'ADMIN_AGGREGATION_RULE_SAVE_SUCCESS',
  ADMIN_AGGREGATION_RULE_SAVE_FAILURE: 'ADMIN_AGGREGATION_RULE_SAVE_FAILURE',
};
