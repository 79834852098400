import { userConstants } from "./authConstants";
import { saveAccessToken, clearAccessToken, saveRefreshToken, clearRefreshToken } from "../../../shared/authAxios";
import { websocketAuth, websocketDeauth } from "../../../shared/ws";

const makeInitialState = () => ({
    requestActive: false,
    loggedIn: false,
    user: {},
    error: "",
});

export function auth(state = makeInitialState(), action) {
    switch (action.type) {
        case userConstants.AUTH_CHECK_REQUEST:
        // fall through
        case userConstants.LOGIN_REQUEST:
            return {
                requestActive: true,
                user: action.user,
            };
        case userConstants.LOGIN_SUCCESS:
            saveAccessToken(action.data.access_token);
            if (action.data.refresh_token) {
                saveRefreshToken(action.data.refresh_token);
            }
        // setTimeout(() => {
        //   return {
        //     user: action.data ? {
        //       user_id: action.data.user_id,
        //       username: action.data.username,
        //       access_tenant_ids: action.data.access_tenant_ids
        //     } : {},
        //   }
        // }, 100);
        // fall through
        case userConstants.AUTH_CHECK_SUCCESS: {
            const ns = {
                loggedIn: true,
                requestActive: false,
                user: action.data ? action.data : {},
            };
            websocketAuth(ns);
            return ns;
        }
        case userConstants.AUTH_CHECK_FAILURE:
            return {
                loggedIn: false,
                user: {},
            };
        case userConstants.LOGIN_FAILURE:
            return {
                requestActive: false,
                error: action.error,
            };
        case userConstants.LOGOUT:
            clearAccessToken();
            clearRefreshToken();
            websocketDeauth();
            return makeInitialState();
        default:
            return state;
    }
}
