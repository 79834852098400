export const dailyReportsConstants = {
  DAILY_REPORT_LIST_REQUEST: 'DAILY_REPORT_LIST_REQUEST',
  DAILY_REPORT_LIST_SUCCESS: 'DAILY_REPORT_LIST_SUCCESS',
  DAILY_REPORT_LIST_FAILURE: 'DAILY_REPORT_LIST_FAILURE',
  DAILY_REPORT_LIST_CHANGED: 'DAILY_REPORT_LIST_CHANGED',
  DAILY_REPORT_LIST_PAGINATION: 'DAILY_REPORT_LIST_PAGINATION',

  DAILY_REPORT_LOAD_REQUEST: 'DAILY_REPORT_LOAD_REQUEST',
  DAILY_REPORT_LOAD_SUCCESS: 'DAILY_REPORT_LOAD_SUCCESS',
  DAILY_REPORT_LOAD_FAILURE: 'DAILY_REPORT_LOAD_FAILURE',

  DAILY_REPORT_SAVE_REQUEST: 'DAILY_REPORT_SAVE_REQUEST',
  DAILY_REPORT_SAVE_SUCCESS: 'DAILY_REPORT_SAVE_SUCCESS',
  DAILY_REPORT_SAVE_FAILURE: 'DAILY_REPORT_SAVE_FAILURE',
};
