export const manufacturersConstants = {
  MANUFACTURERS_LIST_REQUEST: 'MANUFACTURERS_LIST_REQUEST',
  MANUFACTURERS_LIST_SUCCESS: 'MANUFACTURERS_LIST_SUCCESS',
  MANUFACTURERS_LIST_FAILURE: 'MANUFACTURERS_LIST_FAILURE',
  MANUFACTURERS_LIST_CHANGED: 'MANUFACTURERS_LIST_CHANGED',
  MANUFACTURERS_LIST_PAGINATION: 'MANUFACTURERS_LIST_PAGINATION',

  MANUFACTURERS_LOAD_REQUEST: 'MANUFACTURERS_LOAD_REQUEST',
  MANUFACTURERS_LOAD_SUCCESS: 'MANUFACTURERS_LOAD_SUCCESS',
  MANUFACTURERS_LOAD_FAILURE: 'MANUFACTURERS_LOAD_FAILURE',

  MANUFACTURERS_SAVE_REQUEST: 'MANUFACTURERS_SAVE_REQUEST',
  MANUFACTURERS_SAVE_SUCCESS: 'MANUFACTURERS_SAVE_SUCCESS',
  MANUFACTURERS_SAVE_FAILURE: 'MANUFACTURERS_SAVE_FAILURE',
};
