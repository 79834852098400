import { combineReducers } from 'redux';

// import global redux implementations
import { edits } from './reducers/edits';

// import page redux implementations
import { app } from "./reducers/app";
import { rt_notifications } from "./reducers/rt_notifications";

import { auth } from '../views/auth/_redux/authReducer';
import { tenants } from '../views/tenant/_redux/tenantReducer';
import { tenantsGroups } from '../views/tenant-groups/_redux/tenantGroupsReducer';
import { locations } from '../views/location/_redux/locationReducer';
import { locationsGroups } from '../views/location-groups/_redux/locationGroupsReducer';
import { slots } from '../views/slot/_redux/slotReducer';
import { slotsGroups } from '../views/slot-groups/_redux/slotGroupsReducer';
import { smibs } from '../views/smib/_redux/smibReducer';
import { messages } from '../views/messages/_redux/messagesReducer';
import { events } from '../views/events/_redux/eventsReducer';
import { messageMeters } from '../views/message-meters/_redux/messageMetersReducer';
import { entity_groups } from '../views/entity-group/_redux/entityGroupReducer';
import { counters } from './../views/counters/_redux/countersReducer';
import { slotdata } from '../views/slotdata/_redux/slotDataReducer';
import { timelog } from '../views/timelog/_redux/timelogReducer';
import { checkpoints } from '../views/checkpoint/_redux/checkpointReducer';
import { aggregations } from '../views/aggregation/_redux/aggregationReducer';
import { tasks } from '../views/task/_redux/taskReducer';
import { currenttasks } from '../views/task/_redux/currentTaskReducer';
import { dataexports } from './../views/dataexport/_redux/dataexportReducer';
import { rawaggregations } from '../views/rawaggregation/_redux/rawAggregationReducer';
import { logs } from '../views/log/_redux/logReducer';
import { logErrors } from '../views/log-error/_redux/logErrorReducer';
import { jackpots } from '../views/jackpots/_redux/jackpotReducer';
import { jackpotSources } from '../views/jackpot-sources/_redux/jackpotSourceReducer';
import { jackpotHistory } from '../views/jackpot-history/_redux/jackpotHistoryReducer';
import { displayChannels } from '../views/display-channels/_redux/displayChannelReducer';
import { dashboard_settings } from '../views/dashboard/_redux/dashboardReducer';
import { subHeader } from '../_metronic/layout/components/subheader/SubHeaderReducer';
import { timePicker } from '../../src/components/grafana-datetime/_redux/TimePickerReducer';

import { acl_users } from '../views/acl/user/_redux/userReducer';
import { acl_entity_groups } from '../views/acl/entity-group/_redux/entityGroupReducer';
import { acl_permissions } from '../views/acl/permission/_redux/permissionReducer';
import { acl_user_groups } from '../views/acl/user-group/_redux/userGroupReducer';
import { acl_roles } from '../views/acl/role/_redux/roleReducer';
import { acl_rules } from '../views/acl/rule/_redux/ruleReducer';

import { admin_aggregation_rules } from '../views/admin/aggregation-rule/_redux/aggregationRuleReducer';
import { admin_columns_configurations } from '../views/admin/columns-configuration/_redux/columnsConfigurationReducer';
import { admin_general_settings } from '../views/admin/general-settings/_redux/generalSettingsReducer';
import { reports_payment_transactions } from '../views/reports/payment-transactions/_redux/paymentTransactionReducer';
import { reports_collector_checkpoints } from '../views/reports/collector-checkpoints/_redux/collectorCheckpointReducer';

import { dailyReports } from '../views/reports/daily-legal/_redux/dailyLegalReducer';
import { monthlyReports } from '../views/reports/monthly-legal/_redux/monthlyLegalReducer';

import { player_exclusion_players } from '../views/player-exclusion/players/_redux/playersReducer';
import { player_exclusion_selfExclusions } from '../views/player-exclusion/self-exclusions/_redux/selfExclusionsReducer';
import { player_exclusion_playerScans } from '../views/player-exclusion/player-scans/_redux/playerScansReducer';
import { player_exclusion_blacklists } from '../views/player-exclusion/blacklists/_redux/blacklistsReducer';

import { games_registry_machine_types } from '../views/games-registry/machine-types/_redux/machineTypesReducer';
import { games_registry_games } from '../views/games-registry/games/_redux/gamesReducer';
import { games_registry_game_mixes } from '../views/games-registry/game-mixes/_redux/gameMixesReducer';
import { games_registry_game_profiles } from '../views/games-registry/game-profiles/_redux/gameProfilesReducer';
import { games_registry_manufacturers } from '../views/games-registry/manufacturers/_redux/manufacturersReducer';
import { games_registry_protocols } from '../views/games-registry/protocols/_redux/protocolsReducer';

const rootReducer = combineReducers({
  app,
  auth,
  tenants,
  tenantsGroups,
  locations,
  locationsGroups,
  slots,
  slotsGroups,
  entity_groups,
  smibs,
  messages,
  events,
  messageMeters,
  counters,
  slotdata,
  timelog,
  checkpoints,
  edits,
  aggregations,
  tasks,
  dataexports,
  rawaggregations,
  currenttasks,
  logs,
  logErrors,
  jackpots,
  jackpotSources,
  jackpotHistory,
  displayChannels,
  dashboard_settings,
  subHeader,
  timePicker,
  acl_users,
  acl_entity_groups,
  acl_permissions,
  acl_user_groups,
  acl_roles,
  acl_rules,
  admin_aggregation_rules,
  admin_columns_configurations,
  admin_general_settings,
  reports_payment_transactions,
  reports_collector_checkpoints,
  dailyReports,
  monthlyReports,
  rt_notifications,
  player_exclusion_players,
  player_exclusion_selfExclusions,
  player_exclusion_playerScans,
  player_exclusion_blacklists,
  games_registry_machine_types,
  games_registry_games,
  games_registry_game_mixes,
  games_registry_game_profiles,
  games_registry_manufacturers,
  games_registry_protocols
});

export default rootReducer;
