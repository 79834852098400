export const jackpotSourceConstants = {
  JACKPOT_SOURCE_LIST_REQUEST: 'JACKPOT_SOURCE_LIST_REQUEST',
  JACKPOT_SOURCE_LIST_SUCCESS: 'JACKPOT_SOURCE_LIST_SUCCESS',
  JACKPOT_SOURCE_LIST_FAILURE: 'JACKPOT_SOURCE_LIST_FAILURE',
  JACKPOT_SOURCE_LIST_CHANGED: 'JACKPOT_SOURCE_LIST_CHANGED',
  JACKPOT_SOURCE_LIST_PAGINATION: 'JACKPOT_SOURCE_LIST_PAGINATION',

  JACKPOT_SOURCE_LOAD_REQUEST: 'JACKPOT_SOURCE_LOAD_REQUEST',
  JACKPOT_SOURCE_LOAD_SUCCESS: 'JACKPOT_SOURCE_LOAD_SUCCESS',
  JACKPOT_SOURCE_LOAD_FAILURE: 'JACKPOT_SOURCE_LOAD_FAILURE',

  JACKPOT_SOURCE_SAVE_REQUEST: 'JACKPOT_SOURCE_SAVE_REQUEST',
  JACKPOT_SOURCE_SAVE_SUCCESS: 'JACKPOT_SOURCE_SAVE_SUCCESS',
  JACKPOT_SOURCE_SAVE_FAILURE: 'JACKPOT_SOURCE_SAVE_FAILURE',
};
