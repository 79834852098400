export const userConstants = {
  ACL_USER_LIST_REQUEST: 'ACL_USER_LIST_REQUEST',
  ACL_USER_LIST_SUCCESS: 'ACL_USER_LIST_SUCCESS',
  ACL_USER_LIST_FAILURE: 'ACL_USER_LIST_FAILURE',
  ACL_USER_LIST_CHANGED: 'ACL_USER_LIST_CHANGED',
  ACL_USER_LIST_PAGINATION: 'ACL_USER_LIST_PAGINATION',
  ACL_USER_LIST_STATUS: 'ACL_USER_LIST_STATUS',

  ACL_USER_LOAD_REQUEST: 'ACL_USER_LOAD_REQUEST',
  ACL_USER_LOAD_SUCCESS: 'ACL_USER_LOAD_SUCCESS',
  ACL_USER_LOAD_FAILURE: 'ACL_USER_LOAD_FAILURE',

  ACL_USER_SAVE_REQUEST: 'ACL_USER_SAVE_REQUEST',
  ACL_USER_SAVE_SUCCESS: 'ACL_USER_SAVE_SUCCESS',
  ACL_USER_SAVE_FAILURE: 'ACL_USER_SAVE_FAILURE',
};
