/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import {Link, useLocation} from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { SubHeaderActions } from "../subheader/SubHeaderActions";
import { withTranslation } from "react-i18next";

const SubHeader = (props) => {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    let paths = handlePaths();
    setTimeout(() => {
      subheader.setBreadcrumbs(generateBreadCrumb(paths));
      if(props.subHeader.pageTitle){
        subheader.setBreadcrumbs(generateBreadCrumb(paths, props.subHeader.pageTitle));
      }
    }, 100);

    // eslint-disable-next-line
  }, [location.pathname, props.subHeader.pageTitle]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
  }, [subheader]);

  const handlePaths = () => {
    let paths = location.pathname.split("/");
    paths = paths[paths.length-1] === "" ? paths.slice(0, paths.length-1): paths;
    paths = paths[0] === "" ? paths.slice(1) : paths;

    return paths;
  }

  const generateBreadCrumb = (paths, overrideTitle) => {

    let breadcrumb = paths.map((path, index) => {
      if((paths[1] === 'timelog' || 
          paths[1] === 'raw-aggregations' || 
          paths[1] === 'computed-aggregations' || 
          paths[1] === 'checkpoints' || 
          paths[1] === 'remote-cashout') && paths.length === 3){
        paths.push('id'); 
      }
  
      if(paths[0] === 'locations' && paths.length === 1 && location.search){
        paths.push('tenant'); 
      }
      
      if(paths[0] === 'slots' && paths.length === 1 && location.search){
        paths.push('location'); 
      }

      if(paths[0] === 'events' && paths.length === 1 && location.search){
        paths.push('slots'); 
      }

      if(paths[0] === 'display-channels' && paths.length === 1 && location.search){
        paths.push('display-channels'); 
      }else if(paths[0] === 'display-channels' && paths.length === 2 && !location.search){
        paths = [ 'display-channels' ];
      }

      if((paths[1] === 'payment-transaction') && paths.length === 2){
        paths[1] = 'payment-transactions';
        paths.push('id'); 
      }

      let url = '/' + paths.slice(0, index+1).join('/').replace('//', '/');
      let title = path.replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase()).replace('-', ' ');

      switch (title) { // Titles that contain proper names.
        case 'Slot Games': title = "Game Stats"; break;
        case 'Acl': title = "Access Control List"; break;
        case 'Smibs': title = "SMIBs"; break;
        case 'Smib': title = "SMIB"; break;
        case 'Rules': title = "ACL Rules"; break;
        case 'Reports': title = "Reports"; break;
        case 'Api Tokens': title = "API Tokens"; break;
        default: break;
      }

      // if (paths[1] === "daily-reports") {
      //   title = "Daily Reports";
      // } else if (paths[1] === "monthly-reports") {
      //   title = "Monthly Reports";
      // }

      if(!(title.length === 24 && (paths.length - 1) === index) && isNaN(title)){
        title = props.t(title);
      }
      
      if(overrideTitle && (paths.length - 1) === index){
        title = overrideTitle;
      }

      return ({pathname: url, title: title});
    });

    // TODO - this whole section for breadcrumbs needs a proper refactoring
    // if(location.pathname === "/reports/daily-reports"){
    //   delete(breadcrumb[0]);
    // } else if(location.pathname === "/reports/daily-reports/" && location.search){
    //   breadcrumb[0].pathname = "/reports/daily-reports";
    // }
    // if(location.pathname === "/reports/monthly-reports"){
    //   delete(breadcrumb[0]);
    // } else if(location.pathname === "/reports/monthly-reports/" && location.search){
    //   breadcrumb[0].pathname = "/reports/monthly-reports";
    // }


    return breadcrumb;
  }

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        {(location.pathname.startsWith('/dashboard')) &&
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline flex-wrap mr-5"></div>
        </div>
        }
        {!(location.pathname.startsWith('/dashboard')) &&
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline flex-wrap mr-5">
              <BreadCrumbs items={subheader.breadcrumbs} />
            </div>
          </div>
        }

        {/* Toolbar */}
        {(location.pathname.startsWith('/dashboard') && location.pathname.indexOf('/dashboard/edit') === -1) && //only show on dashboard
          <div className="d-flex align-items-center">
            <Link to={`/dashboard/edit`}
                  className="btn btn-label-brand btn-bold">{props.t("Edit Dashboard")}</Link>
          </div>
        }
        {(location.pathname.endsWith('/dashboard/edit')) && //only show on dashboard
          <div className="d-flex align-items-center">
            <button className="btn btn-label-brand btn-bold ml-3" disabled="disabled">{props.t("Editing...")}</button>
          </div>
        }
      </div>
    </div>
  );
}

const mapState = (state) => {
  return { 
    subHeader: state.subHeader
  }
}

const actionCreators = {
  setPageTitle: SubHeaderActions.setPageTitle
}

export default withTranslation()(withRouter(connect(mapState, actionCreators)(SubHeader)));
