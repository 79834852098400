import { aggregationConstants } from './aggregationConstants';
import { userConstants } from "../../auth/_redux/authConstants";
import { utils } from "../../../shared/utils"

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  total: 0,
  error: '',
  startDate: null,
  endDate: null,
  timeRange: null,
  gameId: 0,
  type: '',
  resourceId: '',
  locationIds: null,
  tenantIds: null,
  inProgress: true,
  query: ''
});

export function aggregations(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case aggregationConstants.AGGREGATION_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case aggregationConstants.AGGREGATION_LIST_SUCCESS: {
      let t = state.type;
      if (t === 'slots-demo') t = 'slots';
      if (typeof (action.aggregations.url) != 'undefined' && utils.endsWith(action.aggregations.url, t)) { //prevent late responses overwrite current resultset
        return {
          ...state,
          requestActive: false,
          shouldReload: false,
          items: action.aggregations.data ? action.aggregations.data : [],
          total: action.aggregations.total ? action.aggregations.total : 0,
          inProgress: false,
        }
      } else {
        return state;
      }
    }
    case aggregationConstants.AGGREGATION_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case aggregationConstants.AGGREGATION_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case aggregationConstants.AGGREGATION_LIST_CLEAR_PAGINATION:
      return makeInitialState();
    case aggregationConstants.AGGREGATION_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.gameId !== action.pagination.gameId
        || state.shouldReload
        || state.type !== action.pagination.type
        || state.resourceId !== action.pagination.resourceId
        || state.locationIds !== action.pagination.locationIds
        || state.tenantIds !== action.pagination.tenantIds
        || (action.pagination.startDate && action.pagination.endDate)
        || state.query !== action.pagination.query) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        startDate: action.pagination.startDate,
        endDate: action.pagination.endDate,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        gameId: action.pagination.gameId,
        type: action.pagination.type,
        resourceId: action.pagination.resourceId,
        locationIds: action.pagination.locationIds,
        tenantIds: action.pagination.tenantIds,
        shouldReload,
        query: action.pagination.query,
      };
    }

    default:
      return state
  }
}
