export const messagesConstants = {
  MESSAGES_LIST_REQUEST: 'MESSAGES_LIST_REQUEST',
  MESSAGES_LIST_SUCCESS: 'MESSAGES_LIST_SUCCESS',
  MESSAGES_LIST_FAILURE: 'MESSAGES_LIST_FAILURE',
  MESSAGES_LIST_CHANGED: 'MESSAGES_LIST_CHANGED',
  MESSAGES_LIST_PAGINATION: 'MESSAGES_LIST_PAGINATION',

  MESSAGES_LOAD_REQUEST: 'MESSAGES_LOAD_REQUEST',
  MESSAGES_LOAD_SUCCESS: 'MESSAGES_LOAD_SUCCESS',
  MESSAGES_LOAD_FAILURE: 'MESSAGES_LOAD_FAILURE',

  MESSAGES_SAVE_REQUEST: 'MESSAGES_SAVE_REQUEST',
  MESSAGES_SAVE_SUCCESS: 'MESSAGES_SAVE_SUCCESS',
  MESSAGES_SAVE_FAILURE: 'MESSAGES_SAVE_FAILURE',
};