export const eventsConstants = {
  EVENTS_LIST_REQUEST: 'EVENTS_LIST_REQUEST',
  EVENTS_LIST_SUCCESS: 'EVENTS_LIST_SUCCESS',
  EVENTS_LIST_FAILURE: 'EVENTS_LIST_FAILURE',
  EVENTS_LIST_CHANGED: 'EVENTS_LIST_CHANGED',
  EVENTS_LIST_PAGINATION: 'EVENTS_LIST_PAGINATION',

  EVENTS_LOAD_REQUEST: 'EVENTS_LOAD_REQUEST',
  EVENTS_LOAD_SUCCESS: 'EVENTS_LOAD_SUCCESS',
  EVENTS_LOAD_FAILURE: 'EVENTS_LOAD_FAILURE',

  EVENTS_SAVE_REQUEST: 'EVENTS_SAVE_REQUEST',
  EVENTS_SAVE_SUCCESS: 'EVENTS_SAVE_SUCCESS',
  EVENTS_SAVE_FAILURE: 'EVENTS_SAVE_FAILURE',
};