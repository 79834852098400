import { generalSettingsConstants } from './generalSettingsConstants';
import {userConstants} from "../../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  settings_system_type: "system",
  settings_system_id: "000000000000000000000000",
  user_id: "",
  settings: [],
  error: ''
});

export function admin_general_settings(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case generalSettingsConstants.GENERAL_SETTINGS_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
      };
    case generalSettingsConstants.GENERAL_SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        settings: action.generalSettings.widgets ? action.generalSettings.settings : [],
      };
    case generalSettingsConstants.GENERAL_SETTINGS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case generalSettingsConstants.GENERAL_SETTINGS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };

    case generalSettingsConstants.GENERAL_SETTINGS_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case generalSettingsConstants.GENERAL_SETTINGS_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
        }
      }
      return {
        ...state,
        requestSaveActive: false,
      }
    case generalSettingsConstants.GENERAL_SETTINGS_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
