import { SubHeaderConstants } from './SubHeaderConstants';
import {userConstants} from "../../../../views/auth/_redux/authConstants";

const makeInitialState = () => ({
  pageTitle: null,
});

export function subHeader(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case SubHeaderConstants.SUBHEADER_SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.title
      };
    
    default:
      return state
  }
}
