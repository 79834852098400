import { columnsConfigurationConstants } from './columnsConfigurationConstants';
import {userConstants} from "../../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  columns_configs: [],
  page_name: '',
  page_route: '',
  error: ''
});

export function admin_columns_configurations(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
      };
    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        columns_configs: action.columnsConfigurations.columns_configs ? action.columnsConfigurations.columns_configs : [],
      };
    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };

    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
          columns_configs: [...state.columns_configs, action.columnsConfiguration],
        }
      }
      return {
        ...state,
        requestSaveActive: false,
        columns_configs: state.columns_configs.map(t => t.id === action.columnsConfiguration.id ? action.columnsConfiguration : t)
      }
    case columnsConfigurationConstants.COLUMNS_CONFIGURATION_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
