export const generalSettingsConstants = {
  GENERAL_SETTINGS_LIST_REQUEST: 'GENERAL_SETTINGS_LIST_REQUEST',
  GENERAL_SETTINGS_LIST_SUCCESS: 'GENERAL_SETTINGS_LIST_SUCCESS',
  GENERAL_SETTINGS_LIST_FAILURE: 'GENERAL_SETTINGS_LIST_FAILURE',
  GENERAL_SETTINGS_LIST_CHANGED: 'GENERAL_SETTINGS_LIST_CHANGED',
  GENERAL_SETTINGS_LIST_PAGINATION: 'GENERAL_SETTINGS_LIST_PAGINATION',

  GENERAL_SETTINGS_LOAD_REQUEST: 'GENERAL_SETTINGS_LOAD_REQUEST',
  GENERAL_SETTINGS_LOAD_SUCCESS: 'GENERAL_SETTINGS_LOAD_SUCCESS',
  GENERAL_SETTINGS_LOAD_FAILURE: 'GENERAL_SETTINGS_LOAD_FAILURE',

  GENERAL_SETTINGS_SAVE_REQUEST: 'GENERAL_SETTINGS_SAVE_REQUEST',
  GENERAL_SETTINGS_SAVE_SUCCESS: 'GENERAL_SETTINGS_SAVE_SUCCESS',
  GENERAL_SETTINGS_SAVE_FAILURE: 'GENERAL_SETTINGS_SAVE_FAILURE',
  GENERAL_SETTINGS_DONE_SUCCESS: 'GENERAL_SETTINGS_DONE_SUCCESS'
};
