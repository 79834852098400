import { SubHeaderConstants } from './SubHeaderConstants';

export const SubHeaderActions = {
  setPageTitle,
};

function setPageTitle(title) { 
  return { 
    type: SubHeaderConstants.SUBHEADER_SET_PAGE_TITLE,
    title: title,
  } 
}
