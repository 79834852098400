import { store } from "../store";

export const hasPermission = (permission) => {
    const { auth } = store.getState();

    if (!(auth && auth.user && auth.user.permissions)) {
        console.log("user permissions not found in state");
        //alert("user permissions not found in state");
        return false;
    }

    if (!(permission in auth.user.permissions)) {
        console.log(`permission '${permission}' not found in defined permissions. Check spelling!`);
        //alert(`permission '${permission}' not found in defined permissions. Check spelling!`);
        return false;
    }

    return auth.user.permissions[permission] === 1;
}
