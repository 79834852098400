import { rawaggregationConstants } from './rawAggregationConstants';
import {userConstants} from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  total: 0,
  error: '',
  id: '',
  type: '',
  granulation: '',
  dateStart: null,
  dateEnd: null,
  timeRange: null,
  inProgress: true,
});

export function rawaggregations(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case rawaggregationConstants.RAWAGGREGATION_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case rawaggregationConstants.RAWAGGREGATION_LIST_SUCCESS: {
      const rawaggregations = [];
      let key = 1;
      action.rawaggregations.rows && action.rawaggregations.rows.forEach((e) => {
        const el = { ...e };
        el._key = key++;
        rawaggregations.push(el);
      })

      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: rawaggregations,
        total: action.rawaggregations.total ? action.rawaggregations.total : 0,
        inProgress: false,
      };
    }
    case rawaggregationConstants.RAWAGGREGATION_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case rawaggregationConstants.RAWAGGREGATION_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case rawaggregationConstants.RAWAGGREGATION_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.granulation !== action.pagination.granulation
        || state.shouldReload
        || (action.pagination.dateStart && action.pagination.dateEnd)) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        id: action.pagination.id,
        type: action.pagination.type,
        granulation: action.pagination.granulation,
        dateStart: action.pagination.dateStart,
        dateEnd: action.pagination.dateEnd,
        timeRange: action.pagination.timeRange ? action.pagination.timeRange : state.timeRange,
        shouldReload
      };
    }

    case rawaggregationConstants.RAWAGGREGATION_SAVE_REQUEST:
      return {
        ...state,
        requestSaveActive: true,
      }
    case rawaggregationConstants.RAWAGGREGATION_SAVE_SUCCESS:
      if (action.isNew) {
        return {
          ...state,
          requestSaveActive: false,
        }
      }
      return {
        ...state,
        requestSaveActive: false,
      }
    case rawaggregationConstants.RAWAGGREGATION_SAVE_FAILURE:
      return {
        ...state,
        requestSaveActive: false,
        error: action.error,
      }

    default:
      return state
  }
}
