import { userConstants } from './authConstants';
import { userService } from './authServices';
import axios from "axios";

export const userActions = {
  login,
  logout,
  check,
};

function login(username, password) {
  return dispatch => {
      dispatch(request({ username }));

      return userService.login(username, password)
        .then(
          data => {
            dispatch(success(data));
          },
          error => {
            dispatch(failure(error.toString()));
            throw error;
          }
        );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(data) { return { type: userConstants.LOGIN_SUCCESS, data } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function check(cb) {
  return dispatch => {
    dispatch(request());

    return userService.check()
      .then(
        data => {
          dispatch(success(data));
          if (typeof cb === "function") cb(true);
        },
        error => {
          dispatch(failure(error.toString()));
          if (typeof cb === "function") cb(true, false);
        }
      );
  };

  function request() { return { type: userConstants.AUTH_CHECK_REQUEST } }
  function success(data) { return { type: userConstants.AUTH_CHECK_SUCCESS, data } }
  function failure(error) { return { type: userConstants.AUTH_CHECK_FAILURE, error } }
}

function logout(cb) {
  return dispatch => {

    return userService.logout().then(
      () => {
        dispatch(request());
        if (typeof cb === "function") cb(true);
      },
      () => {
        dispatch(request());
        if (typeof cb === "function") cb(true, false);
      }
    );
  };

  function request() { return { type: userConstants.LOGOUT } }
}
