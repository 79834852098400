import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { appHideVersionCheckModal, appBuildInfoReceived } from "../../store/actions/app";

const VersionCheck = props => {
    const { thisVersion, remoteVersion, newVersionAvailable, versionModalShown, hideModal, buildInfoReceived } = props;

    //console.log("props = ", props);

    useEffect(() => {
        if (!thisVersion) {
            return;
        }

        const tid = setInterval(() => {
            axios.get("/version.json").then(res => {
                buildInfoReceived(res.data);
            });
        }, 60 * 1000);

        return () => {
            clearInterval(tid);
        }
    }, [thisVersion, buildInfoReceived]);

    if (!newVersionAvailable) {
        console.log("no version in build");
        return null;
    }

    const handleRefresh = () => {
        window.location.reload();
    }
    
    const handleClose = () => {
        hideModal();
    };

    return (
        <div>
            <Dialog
                open={versionModalShown}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"New version available"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        New version available ({remoteVersion.commit} vs {thisVersion.commit})
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefresh} color="primary">
                        Refresh
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Later
                    </Button>
                </DialogActions>
            </Dialog>{" "}
        </div>
    );
};

const mapStateToProps = state => ({
    thisVersion: state.app.thisVersion,
    remoteVersion: state.app.remoteVersion,
    newVersionAvailable: state.app.newVersionAvailable,
    versionModalShown: state.app.versionModalShown,
});

const actionCreators = {
    hideModal: appHideVersionCheckModal,
    buildInfoReceived: appBuildInfoReceived,
};

export default connect(mapStateToProps, actionCreators)(VersionCheck);
