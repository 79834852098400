import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';

import { Layout } from "../_metronic/layout";
import { AuthPage } from "../views/auth/AuthPage";
import Logout from "../views/auth/Logout";
import { userActions } from '../views/auth/_redux/authActions';

import BasePage from "../layout/BasePage";

const ErrorsPage = React.lazy(() => import('../views/_not_found/ErrorsPage'));
const DailyReport = React.lazy(() => import('../views/reports/daily-legal/DailyReport'));
const DailyJackpotReport = React.lazy(() => import('../views/reports/daily-legal/DailyJackpotReport'));
const MonthlyReport = React.lazy(() => import('../views/reports/monthly-legal/MonthlyReport'));
const MonthlyJackpotReport = React.lazy(() => import('../views/reports/monthly-legal/MonthlyJackpotReport'));

const Routes = (props) => {
    const { isAuthorized, check } = props;
    const [checking, setChecking] = React.useState(!isAuthorized ? true : false);

    React.useEffect(() => {
        if (!isAuthorized) {
            check(() => {
                setChecking(false);
            });
        }
    }, []); // eslint-disable-line

    if (checking) return null;

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                    /*Otherwise redirect to root page (`/access`)*/
                    <Redirect from="/auth" to="/access" />
                )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />
            <Route path="/reports/daily-report/:id" component={DailyReport} exact={true} />
            <Route path="/reports/daily-report/:id/:exportType" component={DailyReport} exact={true} />
            <Route path="/reports/daily-jackpot-report/:id" component={DailyJackpotReport} exact={true} />
            <Route path="/reports/daily-jackpot-report/:id/:exportType" component={DailyJackpotReport} exact={true} />
            <Route path="/reports/monthly-report/:locationIdYearMonth" component={MonthlyReport} exact={true} />
            <Route path="/reports/monthly-report/:locationIdYearMonth/:exportType" component={MonthlyReport} exact={true} />
            <Route path="/reports/monthly-jackpot-report/:locationIdYearMonth" component={MonthlyJackpotReport} exact={true} />
            <Route path="/reports/monthly-jackpot-report/:locationIdYearMonth/:exportType" component={MonthlyJackpotReport} exact={true} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
    );
}


function mapState(state) {
    const { auth } = state;
    return {
        isAuthorized: auth.loggedIn,
        requestActive: auth.requestActive
    };
}

const actionCreators = {
    check: userActions.check,
};

export default connect(mapState, actionCreators)(Routes);
