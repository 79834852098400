export const roleConstants = {
  ACL_ROLE_LIST_REQUEST: 'ACL_ROLE_LIST_REQUEST',
  ACL_ROLE_LIST_SUCCESS: 'ACL_ROLE_LIST_SUCCESS',
  ACL_ROLE_LIST_FAILURE: 'ACL_ROLE_LIST_FAILURE',
  ACL_ROLE_LIST_CHANGED: 'ACL_ROLE_LIST_CHANGED',
  ACL_ROLE_LIST_PAGINATION: 'ACL_ROLE_LIST_PAGINATION',

  ACL_ROLE_LOAD_REQUEST: 'ACL_ROLE_LOAD_REQUEST',
  ACL_ROLE_LOAD_SUCCESS: 'ACL_ROLE_LOAD_SUCCESS',
  ACL_ROLE_LOAD_FAILURE: 'ACL_ROLE_LOAD_FAILURE',

  ACL_ROLE_SAVE_REQUEST: 'ACL_ROLE_SAVE_REQUEST',
  ACL_ROLE_SAVE_SUCCESS: 'ACL_ROLE_SAVE_SUCCESS',
  ACL_ROLE_SAVE_FAILURE: 'ACL_ROLE_SAVE_FAILURE',
};
