export const jackpotHistoryConstants = {
  JACKPOT_HISTORY_LIST_REQUEST: 'JACKPOT_HISTORY_LIST_REQUEST',
  JACKPOT_HISTORY_LIST_SUCCESS: 'JACKPOT_HISTORY_LIST_SUCCESS',
  JACKPOT_HISTORY_LIST_FAILURE: 'JACKPOT_HISTORY_LIST_FAILURE',
  JACKPOT_HISTORY_LIST_CHANGED: 'JACKPOT_HISTORY_LIST_CHANGED',
  JACKPOT_HISTORY_LIST_PAGINATION: 'JACKPOT_HISTORY_LIST_PAGINATION',

  JACKPOT_HISTORY_LOAD_REQUEST: 'JACKPOT_HISTORY_LOAD_REQUEST',
  JACKPOT_HISTORY_LOAD_SUCCESS: 'JACKPOT_HISTORY_LOAD_SUCCESS',
  JACKPOT_HISTORY_LOAD_FAILURE: 'JACKPOT_HISTORY_LOAD_FAILURE',

  JACKPOT_HISTORY_DONE_SUCCESS: 'JACKPOT_HISTORY_DONE_SUCCESS'
};
