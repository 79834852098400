export const gameMixesConstants = {
  GAME_MIXES_LIST_REQUEST: 'GAME_MIXES_LIST_REQUEST',
  GAME_MIXES_LIST_SUCCESS: 'GAME_MIXES_LIST_SUCCESS',
  GAME_MIXES_LIST_FAILURE: 'GAME_MIXES_LIST_FAILURE',
  GAME_MIXES_LIST_CHANGED: 'GAME_MIXES_LIST_CHANGED',
  GAME_MIXES_LIST_PAGINATION: 'GAME_MIXES_LIST_PAGINATION',

  GAME_MIXES_LOAD_REQUEST: 'GAME_MIXES_LOAD_REQUEST',
  GAME_MIXES_LOAD_SUCCESS: 'GAME_MIXES_LOAD_SUCCESS',
  GAME_MIXES_LOAD_FAILURE: 'GAME_MIXES_LOAD_FAILURE',

  GAME_MIXES_SAVE_REQUEST: 'GAME_MIXES_SAVE_REQUEST',
  GAME_MIXES_SAVE_SUCCESS: 'GAME_MIXES_SAVE_SUCCESS',
  GAME_MIXES_SAVE_FAILURE: 'GAME_MIXES_SAVE_FAILURE',
};
