export const entityGroupConstants = {
  ACL_ENTITY_GROUP_LIST_REQUEST: 'ACL_ENTITY_GROUP_LIST_REQUEST',
  ACL_ENTITY_GROUP_LIST_SUCCESS: 'ACL_ENTITY_GROUP_LIST_SUCCESS',
  ACL_ENTITY_GROUP_LIST_FAILURE: 'ACL_ENTITY_GROUP_LIST_FAILURE',
  ACL_ENTITY_GROUP_LIST_CHANGED: 'ACL_ENTITY_GROUP_LIST_CHANGED',
  ACL_ENTITY_GROUP_LIST_PAGINATION: 'ACL_ENTITY_GROUP_LIST_PAGINATION',

  ACL_ENTITY_GROUP_LOAD_REQUEST: 'ACL_ENTITY_GROUP_LOAD_REQUEST',
  ACL_ENTITY_GROUP_LOAD_SUCCESS: 'ACL_ENTITY_GROUP_LOAD_SUCCESS',
  ACL_ENTITY_GROUP_LOAD_FAILURE: 'ACL_ENTITY_GROUP_LOAD_FAILURE',

  ACL_ENTITY_GROUP_SAVE_REQUEST: 'ACL_ENTITY_GROUP_SAVE_REQUEST',
  ACL_ENTITY_GROUP_SAVE_SUCCESS: 'ACL_ENTITY_GROUP_SAVE_SUCCESS',
  ACL_ENTITY_GROUP_SAVE_FAILURE: 'ACL_ENTITY_GROUP_SAVE_FAILURE',
};
