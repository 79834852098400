export const playersConstants = {
  PLAYERS_LIST_REQUEST: 'PLAYERS_LIST_REQUEST',
  PLAYERS_LIST_SUCCESS: 'PLAYERS_LIST_SUCCESS',
  PLAYERS_LIST_FAILURE: 'PLAYERS_LIST_FAILURE',
  PLAYERS_LIST_CHANGED: 'PLAYERS_LIST_CHANGED',
  PLAYERS_LIST_PAGINATION: 'PLAYERS_LIST_PAGINATION',

  PLAYERS_LOAD_REQUEST: 'PLAYERS_LOAD_REQUEST',
  PLAYERS_LOAD_SUCCESS: 'PLAYERS_LOAD_SUCCESS',
  PLAYERS_LOAD_FAILURE: 'PLAYERS_LOAD_FAILURE',

  PLAYERS_SAVE_REQUEST: 'PLAYERS_SAVE_REQUEST',
  PLAYERS_SAVE_SUCCESS: 'PLAYERS_SAVE_SUCCESS',
  PLAYERS_SAVE_FAILURE: 'PLAYERS_SAVE_FAILURE',
};
