import { rtNotificationsConstants } from "../constants/rt_notifications";

const DEBUG = false;

let makeInitialState;

if (!DEBUG) {
    makeInitialState = () => ({
        notifications: [],
    });
} else {
    makeInitialState = () => ({
        notifications: [
            {
                id: "62cf96595d9b71c8aeb4cb29",
                timestamp: "2022-07-14T04:06:49.265Z",
                content: { en: "{{ message }}" },
                data: { message: "test" },
                requires_confirmation: false,
                resource_type: "slot",
                resource_details: {
                    location_id: "627148346a02cf71fe4c1582",
                    location_name: "Softbinator",
                    slot_id: "62714933de13d7ab85ff19ad",
                    slot_name: "1",
                    tenant_id: "62047818ea3fd2fd3473efc1",
                    tenant_name: "EQL",
                },
            },
        ],
    });
}

export function rt_notifications(state = makeInitialState(), action) {
    DEBUG && console.log("action = ", action);

    switch (action.type) {
        case rtNotificationsConstants.RT_NOTIFICATION_RECEIVED: {
            const ns = Array.from(state.notifications);
            ns.unshift(action.data);
            return {
                ...state,
                notifications: ns,
            };
        }
        case rtNotificationsConstants.RT_NOTIFICATION_DISMISSED: {
            const idx = state.notifications.findIndex(n => n.id === action.id);
            if (idx !== -1) {
                const ns = [...state.notifications];
                ns.splice(idx, 1);

                return {
                    ...state,
                    notifications: ns,
                };
            }
            return state;
        }
        case rtNotificationsConstants.RT_NOTIFICATION_CONFIRMED: {
            const idx = state.notifications.findIndex(n => n.id === action.id);
            if (idx !== -1) {
                const ns = [...state.notifications];
                ns[idx].confirmed = true;

                return {
                    ...state,
                    notifications: ns,
                };
            }
            return state;
        }
        case rtNotificationsConstants.RT_NOTIFICATION_CLEAR: {
            return {
                ...state,
                notifications: [],
            };
        }
        default:
            return state;
    }
}
