import moment from "moment";
import {isEqual} from "lodash";

export const utils = {
  idToLabelName,
  itemToLabel,
  compareArrays,
  idToItemWithLabel,
  endsWith,
  getExportFileName,
  sortArrayByKey
};


//select collections => name (id)
function idToLabelName(collection, id) {
  const elem = collection.find(el => el.value === id);
  if (elem) return elem.label;
  return id;
}

function idToItemWithLabel(collection, id) {
  const elem = collection.find(el => el.id === id);
  console.log(collection)
  if (elem) return itemToLabel(collection, elem);
  return id;
}

function itemToLabel(item) {
  item.label = `${item.name} (${item.id})`;
  item.value = item.id;
  return item;
}

//compare arrays for save button
function compareArrays(arr1, arr2) {
  return isEqual(arr1, arr2);
}

function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function getExportFileName(objName) {
  if (typeof objName == 'undefined') objName = 'unknown';
  return objName.toLowerCase().replace(" ", "_") + `_${moment().format("DDMMYYYYHHmmss")}.csv`;
}

function sortArrayByKey(arr) {
  return Object.keys(arr).sort().reduce(
    (obj, key) => {
      obj[key] = arr[key];
      return obj;
    },
    []
  );
}
